<template>
  <b-container>
        <div class="form-inline">            
            <h2 class="my-4 d-inline" >Slide Rental</h2>
            <div class="ml-auto">
                <b-alert
                    fade
                    :show="alert.countDown"
                    @dismissed="alert.countDown=0"
                    variant="warning"                     
                    class="d-inline py-2 mr-3" 
                >
                {{alert.message}}
                </b-alert>
                <b-button :variant="((needSave)?'':'outline-') + 'success'" @click="save">Save</b-button>
            </div>
        </div>

        <b-form-row class="my-2">
            <b-col cols="2" class="px-2">
                <b-form-group label="Date of take out :" :disabled="isDisabled">
                    <b-form-input type="date" v-model="rentalForm.dateTakeout"/>
                </b-form-group>
            </b-col>
            <b-col cols="6" class="doc-form">
                <b-form-row class="mb-2">Reason : </b-form-row>
                <b-form-row class="form-inline">
                    <b-form-radio name="reason" value="refer" v-model="rentalForm.reason" :disabled="isDisabled">refer for further management </b-form-radio>
                    <b-input size="sm" v-if="rentalForm.reason=='refer'" v-model="rentalForm.reasonOther" />
                </b-form-row>
                <b-form-row >
                    <b-form-radio name="reason" value="consultation" v-model="rentalForm.reason" :disabled="isDisabled">consultation</b-form-radio>
                </b-form-row>
                <b-form-row class="form-inline">
                    <b-form-radio name="reason" value="other" v-model="rentalForm.reason" :disabled="isDisabled">other</b-form-radio>
                    <b-input size="sm" class="w-50" v-if="rentalForm.reason=='other'" v-model="rentalForm.reasonOther" />
                </b-form-row>
            </b-col>
    
            <b-col cols="2">
                <b-form-group label="Date of return :" v-if="isDisabled">
                    <b-form-input type="date" v-model="rentalForm.dateReturn"/>
                </b-form-group>
            </b-col>

            <b-col cols="2">                
                <b-form-group label="Return Note :" v-if="isDisabled">                        
                    <b-form-input v-model="rentalForm.returnNote"/>
                </b-form-group>
            </b-col>
        </b-form-row>
        <!-- <pre>{{rentalForm.specimens}}</pre> -->
        
        <SpecimenNumber 
            v-for="(specimen,index) in rentalForm.specimens" 
            :specimen="specimen" 
            :key="index" 
            :index="index" 
            :disabled="isDisabled"
            class="border-top"            
        />
        
        <b-button @click="add" :disabled="isDisabled">Add Manual</b-button>
        <b-button @click="showSpecimenSelector" :disabled="isDisabled" class="ml-2">Add From Records</b-button>

        <SpecimenSelector ref="specimenModal"/>
        
  </b-container>
</template>

<script>

import SpecimenSelector from '../components/SpecimenSelector.vue'
import SpecimenNumber from '../components/SpecimenNumber.vue'

import axios from 'axios'

export default {
    components : {
        SpecimenNumber,
        SpecimenSelector,
    },

    data(){
        return {
            alert       : {message : '',countDown :0},
            needSave    : false,
            rentalForm  : {
                specimens : [],
                dateTakeout : '',
                reason : '',
                reasonOther : '',
            }            
        }
    },

    computed : {
        isDisabled(){
            return this.$route.name == 'SlideRentalReturn'
        }
    },

    watch: {
        rentalForm : {
            deep: true,
            handler(){
                this.needSave   = true
            }
        }
    },

    mounted(){        
        if(['SlideRentalInfo','SlideRentalReturn'].indexOf(this.$route.name) != -1){
            axios(`/api/sliderentals/${this.$route.params.id}`)
            .then( res => {
                this.rentalForm = res.data
                this.$nextTick( () => { this.needSave = false })
            })
        }
        else{
            //this.add()
        }
    },

    methods : {
        showSpecimenSelector(){
            this.$refs.specimenModal
            .show()
            .then(specimen => {
                this.rentalForm.specimens.push({
                    ref : specimen._id,
                    info : specimen,
                    number      : specimen.number,
                    patient     : specimen.patient._id,
                    slide       : 0,
                    paraffin    : 0,
                    slideNumbers   : {},
                    paraffinNumbers : {},
                })
            })
        },

        add(){
            this.rentalForm.specimens.push({ref:null,number:'',slide:1,slideNumbers:{},paraffinNumbers:{} })
        },

        remove(index){
            this.rentalForm.specimens.splice(index,1)
        },
        
        save(){
            let method      = (this.rentalForm._id)? 'PUT' : 'POST'
            let btn         = event.srcElement
            btn.disabled    = true

            axios({
                method,
                url : `/api/sliderentals/${(this.rentalForm._id)? this.rentalForm._id : ''}`,
                data  : this.rentalForm,
            })
            .then(res => {                
                this.rentalForm     = res.data
                if(method == 'POST')    this.$router.replace('/slide/' + res.data._id)
                                
                this.needSave           = false            
                this.alert.message      = 'Save done'
            })
            .catch( err => {
                this.alert.message      = err.response.data.message || err.message
            })
            .finally( a => {
                this.alert.countDown    = 5
                btn.disabled            = false
            })
        },
    }

}
</script>

<style scoped>
.doc-form input[type="text"]{
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.4rem;
    margin-left: 5px;
    margin-top:5px;
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


</style>