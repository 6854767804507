<template>
    <b-modal ref="confirm-modal" title="Confirm" ok-variant="warning" ok-title="Confirm" @ok="preAuthen">
        <form @submit.stop.prevent="preAuthen">
            <b-form-group label="Password :" :description="authenState">
                <b-form-input type="password" placeholder="Enter your password" v-model="confirmPass"/>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            confirmPass : '',
            authenState : '',
            promise : null,
            resolve : null,
        }
    },

    methods: {
        preAuthen(bvModalEvt){
            bvModalEvt.preventDefault()            
            this.authenState    = ''        
            axios.post(`/api/session/authen`,{password:this.confirmPass})
            .then( res => {
                this.resolve()
                this.$refs['confirm-modal'].hide()                
            })
            .catch( res => {
                this.authenState    = 'Authen Fail'
            })
        },

        show(){
            var self            = this
            
            this.authenState    = ''
            this.confirmPass    = ''            
            this.$refs['confirm-modal'].show()
            this.promise        = new Promise(function(resolve, reject) { self.resolve = resolve })

            return this.promise
        },

    }
}
</script>