<template>
  	<b-container>
		<b-row class="my-4 px-2">
			<b-col class="d-flex">
				<h3 class="mb-0">Patients</h3>
				<b-button variant="success" @click="newPatient" class="mx-2">New</b-button>
				<b-button @click="showHosXPModal">From HosXP</b-button>
			</b-col>
			<b-col class="text-right">
				<b-form-group label-cols-sm="4" class="mb-0">
				<b-input-group>
					<b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
					<b-dropdown-item
						@click="search.by=$event.srcElement.text"
						v-for="option in search.options"
						:key="option"
					>{{option}}</b-dropdown-item>
					</b-dropdown>
					<b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
					<b-input-group-append>
					<b-button size="sm" @click="searchPatient">
						<i class="fas fa-search"></i>Search
					</b-button>
					</b-input-group-append>
				</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<!-- Main table element -->
		<b-table striped hover :items="patients" :fields="fields">		
			<template v-slot:cell(actions)="row">				
				<router-link :to="{ name: 'NewSpecimen', params: { 'id': row.item._id } }">
					<b-button variant="success" size="sm" class="mr-1">Check in</b-button>
				</router-link>
				<b-button size="sm" variant="outline-secondary" @click="editPatient(row.index)">Edit</b-button>				
			</template>
			<template v-slot:cell(name)="row">
				{{row.item.title}} {{row.item.name}} {{row.item.surname}}
			</template>
			<template v-slot:cell(createBy)="row">
				{{usernameById(row.item.createBy)}}
			</template>
			<template v-slot:cell(birthDate)="row">
				<span v-if="row.item.birthDate">
					{{(new Date().getYear() + 1900) - parseInt(row.item.birthDate.substr(0,4))}}
				</span>				
			</template>
		</b-table>

		<b-pagination
			v-model="paginate.page"
			:total-rows="paginate.counts"
			:per-page="paginate.pagesize"
			align="fill"
        />

		<!-- Info modal -->
		<b-modal ref="patient-modal"  title="Patient Info" @ok="save" size="lg">
			<b-form>
				<b-row>
					<b-col cols="2">
						<b-form-group label="Sex :">
						<b-form-select v-model="patientForm.sex">
							<option value="male">ชาย</option>
							<option value="female">หญิง</option>
						</b-form-select>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group label="Title :">
						<b-form-input list="patient-title-list" v-model="patientForm.title"></b-form-input>
						<datalist id="patient-title-list">
							<option v-for="title in titleOption" :key="title">{{title}}</option>
						</datalist>
						</b-form-group>
					</b-col>
					<b-col cols="4" class="px-0">
						<b-form-group label="Name :">
						<b-form-input v-model="patientForm.name"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="4">
						<b-form-group label="Surname :">
						<b-form-input v-model="patientForm.surname"></b-form-input>
						</b-form-group>
					</b-col>          
				</b-row>
				<b-row>					
					<b-col cols="3">
						<b-form-group label="Birth date :">
							<b-form-input type="date" v-model="patientForm.birthDate"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group label="Age :">
							<b-form-input type="number" v-model.number="patientForm.ages"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="3">
						<b-form-group label="ID Card :">
							<b-form-input type="text" maxlength="13" v-model.number="patientForm.cid"></b-form-input>
						</b-form-group>
					</b-col>
					<b-col cols="2">
						<b-form-group label="HN :">
							<b-form-input v-model="patientForm.hn"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
				<!-- 
				<b-row>				
					<b-col cols="4">
						<b-form-group label="Type :">
						<b-form-input v-model="patientForm.type"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row> 
				-->
			</b-form>
			<hr />
			<h5>Specimens</h5>
			<b-card-group >
				<b-card
				border-variant="success"
				:header="specimen.number"
				style="max-width: 10rem;"
				v-for="specimen in patientSpecimens"
				:key="'sp_' + specimen._id"
				>
				<b-card-text>
					<p>{{specimen.type}}</p>
					<router-link :to="{ name: 'SpecimenInfo', params: {id:specimen._id, patient:patientForm} }">
						Open
					</router-link>
				</b-card-text>
				</b-card>
			</b-card-group>
		</b-modal>
		<HosXP ref="hosxp-modal"/>
  	</b-container>
</template>

<script>
/* eslint-disable */
import axios from "axios"
import { mapState } from 'vuex'

import HosXP from './HosXP.vue'

const ONE_YEAR	= (365*24*60*60*1000)

export default {
	components :{
		HosXP
	},

	data() {
		return {
			search: {
				options: ["name", "hn", "cid"],
				keyword: "",
				by: "name"
			},
			fields: [
				{ key: "cid", label: "CID" },
				{ key: "hn", label: "HN" },
				{ key: "name", label: "Patient Name" },
				{ key: "birthDate", label: "Age" },
				{ key: "createBy", label: "Create By" },
				{ key: "actions", label: "Actions" }
			],
			paginate : { counts : 0, page:1,pagesize: 30},
			patients: [],
			patientForm: {},
			patientSpecimens: [],
			titleOption: ["นาย","นาง","นางสาว", "เด็กชาย", "เด็กหญิง", "พระภิกษุ"],			
		}
	},
  	computed: {
		...mapState('user',{users: state => state.data}),


		sortOptions() {
		// Create an options list from our fields
		return this.fields
			.filter(f => f.sortable)
			.map(f => {
			return { text: f.label, value: f.key };
			});
		}
	},

	watch : {
		'patientForm.birthDate' : function(value){
			let date		= new Date(value)
			if(!date) return

			// this.patientForm.ages	= (new Date).getYear() - date.getYear()
			this.patientForm.ages	= Math.floor((Date.now() - date) / ONE_YEAR)
		},

		'paginate.page' : function(page){
			axios
			.get(`/api/patients/?page=${page}`)
			.then(r => this.patients = r.data)			
		}		
	},
	  
	mounted() {
		axios.get(`/api/patients`).then(r => this.patients = r.data)
		axios.get(`/api/patients/count`).then(r => {
			this.paginate.counts	= r.data.counts
            this.paginate.pagesize	= r.data.pagesize
		})
	},

  	methods: {
		usernameById(id){
			const user    = this.users.find( u => u._id === id)
			return user && user.username
		},

		showHosXPModal() {
			this.patientForm 		= {};
			this.patientSpecimens 	= [];
			this.$refs["hosxp-modal"].$refs['modal'].show()
		},

		newPatient(item, index, button) {
			this.patientForm 			= {};
			this.patientSpecimens 		= [];
			this.$refs["patient-modal"].show();
		},

		editPatient(index) {
			this.patientForm 	= this.patients[index]		
			axios.get(`/api/patients/${this.patientForm._id}/specimens`)
			.then(r => {
				this.patientSpecimens	= r.data
				this.$refs["patient-modal"].show();
			})
		},

		save(bvModalEvt) {
			bvModalEvt.preventDefault();

			let method = this.patientForm._id ? "PUT" : "POST";
			let thisView = this;
			let path = this.patientForm._id ? this.patientForm._id : "";

			axios({
				method,
				url: `/api/patients/${path}`,
				data: this.patientForm,
				withCredentials: true
			})
			.then(r => r.data)
			.then(data => {
				thisView.patientForm = data;
				if (method == "POST") this.patients.push(data);
				this.$refs["patient-modal"].hide();
			})
			.catch(err => {
				//thisView.displayMessage(err.response.data.message)
			});
		},

		async searchPatient() {
			let query 	= this.search.keyword.length? `?${this.search.by}=${this.search.keyword}`: "";
			
			axios.get(`/api/patients${query}`)
			.then(r => this.patients	= r.data)
		},

		

		onFiltered(filteredItems) {
		// Trigger pagination to update the number of buttons/pages due to filtering
		this.totalRows = filteredItems.length;
		this.currentPage = 1;
		}
 	}
};
</script>

<style scoped>

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

</style>
