<template>
    <div class="pt-2">
        <b-form-row>
            <b-col cols="4" class="pr-0 text-letter">
                <b-form-group :label="(index + 1) + ' : Specimen Number'" :description="specimenStatus" :disabled="Boolean(specimen.ref)">
                    <b-form-input size="sm" maxlength="1" @input="numCode(0)" :value="specimen.number.charAt(0)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(1)" :value="specimen.number.charAt(1)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(2)" :value="specimen.number.charAt(2)" autocomplete="off"/>
                    <label class="text-center" style="min-width:12px">-</label>
                    <b-form-input size="sm" type="number" @input="numCode(3)" :value="specimen.number.charAt(3)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(4)" :value="specimen.number.charAt(4)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(5)" :value="specimen.number.charAt(5)" autocomplete="off"/>
                    <b-form-input size="sm" maxlength="1" @input="numCode(6)" :value="specimen.number.charAt(6)" autocomplete="off"/>                        
                    <span></span>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="Type :" >
                    <b-form-select size="sm" :options="specimenType" :value="specimenInfo.type" disabled/>
                </b-form-group>
            </b-col>
            <b-col cols="1">
                <b-form-group label="Slide :" >
                    <b-form-input
                        size="sm"
                        type="number" 
                        v-model.number="specimen.slide" 
                        :disabled="Boolean(specimenInfo.dataForm.containers && specimenInfo.dataForm.containers.length) || disabled"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="1">
                <b-form-group label="Paraffin :" v-if="['cellblock','tissueorgan'].indexOf(specimen.type) == -1" >                        
                    <b-form-input
                        size="sm"
                        type="number" 
                        v-model.number="specimen.paraffin" 
                        :disabled="Boolean(specimenInfo.dataForm.containers && specimenInfo.dataForm.containers.length) || disabled"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="1">
                <b-form-group label="Report :" >
                    <b-form-input
                        size="sm"
                        type="number" 
                        v-model.number="specimen.reports" 
                        :disabled="Boolean(disabled)"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="3" >
                <b-form-group label="Name :" class="form-inline">
                    <b-form-input
                        size="sm"
                        class="w-100"
                        v-if="specimenInfo.patient"
                        :value="[specimenInfo.patient.title,specimenInfo.patient.name,specimenInfo.patient.surname].join(' ')"  
                        disabled
                    />
                    <b-form-input
                        class="float-left" 
                        size="sm" 
                        v-if="!specimenInfo.patient"
                        v-model="specimen.patientName"
                        placeholder="Full Name"
                    />
                    <b-form-input
                        class="w-25"
                        size="sm" 
                        v-if="!specimenInfo.patient"
                        v-model="specimen.patientHN"
                        placeholder="HN"
                    />
                </b-form-group>
            </b-col>
            <b-col cols="1">
                <b-form-group label=" " >
                    <b-button variant="outline-danger"  size="sm" @click="$parent.remove(index)" class="text-center" :disabled="disabled"><i class="fas fa-minus"></i></b-button>
                </b-form-group>
            </b-col>
        </b-form-row>

        <b-form-row v-if="specimenInfo.dataForm.containers && specimenInfo.dataForm.containers.length">
            <b-col><b>Slide ({{slideCount}})</b></b-col>
            <b-col><b>Paraffin ({{paraffinCount}})</b></b-col>            
        </b-form-row>

        <b-form-row v-if="specimenInfo.dataForm.containers && specimenInfo.dataForm.containers.length">
            <b-col>                
                <div v-for="letter in letters" :key="letter">
                    <label style="width:25px;"><b>{{String.fromCharCode(64 + letter)}}</b> : </label>
                    <b-form-checkbox-group
                        v-if="specimen.slideNumbers"
                        v-model="specimen.slideNumbers[String.fromCharCode(64 + letter)]"
                        :options="Array.from(new Array(containerSizeByLetter(String.fromCharCode(64 + letter))),(x,index)=> index+1)"                            
                        :name="'slide-' + String.fromCharCode(64 + letter)"
                        class="d-inline"
                    />
                </div>                
            </b-col>
            <b-col >
                <div v-for="letter in letters" :key="letter">
                    <label style="width:25px;"><b>{{String.fromCharCode(64 + letter)}}</b> : </label>
                    <b-form-checkbox-group
                        v-if="specimen.paraffinNumbers"
                        v-model="specimen.paraffinNumbers[String.fromCharCode(64 + letter)]"
                        :options="Array.from(new Array(containerSizeByLetter(String.fromCharCode(64 + letter))),(x,index)=> index+1)"                            
                        :name="'slide-' + String.fromCharCode(64 + letter)"
                        class="d-inline"
                    />
                </div>                    
            </b-col>
        </b-form-row>
    </div>
</template>

<script>

import axios from "axios"

const lettersCount = target =>{
    if(!target)  return 0
    let sum = 0
    
    Object.keys(target).forEach( letter => {sum += target[letter].length })

    return sum
}


export default {
    props : ['index','specimen','disabled'],
    data(){
        return {
            specimenType    : [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
                { value: 'aoutlab', text: 'Outlab' },
            ],
            specimenInfo    : {type:'',dataForm : {}},
            specimenStatus  : 'Enter specimen number',
            snumber : Array.from(new Array(6),x=>' '),
        }
    },

    computed: {
        serialNumber(){
            return this.snumber.join('')
        },

        slideCount(){
            this.specimen.slide = lettersCount(this.specimen.slideNumbers)
            return this.specimen.slide
        },

        paraffinCount(){
            this.specimen.paraffin = lettersCount(this.specimen.paraffinNumbers)
            return this.specimen.slide
        },

        letters(){
            if(!this.specimenInfo.dataForm) return 0
            let containers  = this.specimenInfo.dataForm.containers            
            return Object.keys(containers[containers.length - 1]).length - 2
        }
    },
    
    watch : {
        'specimen.number' : function(newNumber){
            //if(newNumber.length == 7) this.loadSpecimenInfo()
        },

        specimen(){            
            if(this.specimen.ref){
                this.specimenInfo   = this.specimen.info || {type:'',dataForm : {}}
                this.loadSpecimenInfo(this.specimen.ref)                
            }
            else{
                this.specimenInfo = {type:'',dataForm : {}}
            }
            // if(!this.specimen.slideNumbers)  this.specimen.slideNumbers = {}
            // if(!this.specimen.paraffinNumbers)  this.specimen.paraffinNumbers = {}
        }
    },
    
    mounted(){
        if(this.specimen.ref){
            this.specimenInfo   = this.specimen.info || {type:'',dataForm : {}}            
            this.loadSpecimenInfo(this.specimen.ref)
        }
        //if(this.specimen.number.length == 7)    this.loadSpecimenInfo()
    },

    methods : {
        loadSpecimenInfo(ref){
            axios(`api/specimens/info/${ref}`)
            .then(res  => {
                this.specimenInfo       = res.data                

                // if(!res.data.length){
                //     this.specimen.patient   = null
                //     this.specimen.slide     = null
                //     this.specimen.ref       = null
                //     return this.specimenStatus     = 'Not found specimen'                
                // }                                
                // this.specimen.ref       = this.specimenInfo._id     

                // if(!this.specimen.slideNumbers)  this.specimen.slideNumbers = {}                
                // if(!this.specimen.paraffinNumbers)  this.specimen.paraffinNumbers = {}                                
                // this.specimen.slide     = 1
                // this.specimen.patient   = this.specimenInfo.patient._id
                // this.specimenStatus     = `${this.specimenInfo.dataForm.totalSlide} slides and record on ${this.specimenInfo.date || '-'}`
            })

        },
        numCode(index){
            if(!event.data) return
            event.srcElement.value  = event.data.toUpperCase()
            let no                  = this.specimen.number
            this.specimen.number    = no.substr(0,index) + event.srcElement.value + no.substr(index+1)

            
            let next    = event.srcElement.nextElementSibling
            if(!next.select){
                next    = (next.nextElementSibling)? next.nextElementSibling : next
            }            
            next.select && next.select()
        },

        containerSizeByLetter(letter){
            if(!this.specimenInfo.dataForm) return 0
            let containers  = this.specimenInfo.dataForm.containers
            return containers[containers.length - 1][letter]
        }

    }
}
</script>

<style scoped>

.text-letter input{
    width: 34px;
    margin-right: 3px;
    font-size: 1rem;
    text-align: center;
    padding: 0px;    
    display: inline-block;    
}

.input-group-text{
    font-family: monospace;
    font-weight: bold;
    font-size: 1.1rem !important;
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

</style>