<template>
<div>
    <div class="bg-info" id="loginView" style="position: fixed;width:100%;height:100%" v-if="!hasSession">
      <LoginView></LoginView>
    </div>

    <!--
    <div class="rounded row" style="height:600px" v-if="!hasPermission">
        <div class="m-auto text-center">
            <h4>{{session.user && session.user.username}} : {{session.user && session.user.role}} </h4>
            <h6><a class="btn-primary btn" href="/viewer">Report Viewer</a></h6>
            <i class="fas fa-exclamation-triangle text-muted mt-5" style="font-size: 7em;"></i>
            <h5 class="mt-5 text-muted">ไม่ได้รับอนุญาตให้เข้าถึง</h5>
            <b-button variant="warning" @click="confirmLogout" class="text-white mt-3" >
                <i class="fas fa-sign-out-alt"></i> Log out
            </b-button>
        </div>        
    </div> 
    -->

    <div id="app" v-if="hasSession">
        <b-navbar toggleable="sm" type="dark" variant="info">
            <b-container>
                <b-navbar-nav id="nav"  v-if="session.user && session.user.role">
                    <img src="logomini.png" height="44" class="mr-1"/>

                    <b-nav-item v-for="menu in menuForRole[session.user.role]" :key="'menu_' + menu.text">
                        <router-link class="text-white" :to="menu.to"><i :class="menu.icon" /> {{menu.text}}</router-link>
                    </b-nav-item>

                </b-navbar-nav>
                <div class="ml-auto text-white">
                    <span class="mr-2 text-muted">{{session.user && session.user.role}}</span>
                    <b-button size="sm" variant="warning" @click="confirmLogout" >
                        <i class="fas fa-sign-out-alt"></i>Log out
                    </b-button>            
                </div>
            </b-container>
        </b-navbar>
        <keep-alive include="Specimens,Reports">
            <router-view></router-view>      
        </keep-alive>
    </div>    
</div>

</template>
<script>

const NAV   = {
    patient : {icon: 'fas fa-address-card', to: '/patients', text: 'Patient'},
    requisition : {icon: 'fas fa-exchange-alt', to: '/requisition', text: 'Requisition'},
    specimen : {icon: 'fas fa-microscope', to: '/specimen', text: 'Specimen'},
    report : {icon: 'fas fa-notes-medical', to: '/report', text: 'Report'},
    viewer : {icon: 'fas fa-notes-medical', to: '/viewer-report', text: 'View Report'},
    slide : {icon: 'fas fa-exchange-alt', to: '/slide', text: 'Slide'},
    rental : {icon: 'fas fa-exchange-alt', to: '/rental/create', text: 'Block/Slide/Report'},
    user : {icon: 'fas fa-users', to: '/user', text: 'User'},
    department : {icon: 'far fa-building', to: '/department', text: 'Department'},
    stat : {icon: 'fas fa-chart-bar', to: '/stats', text: 'Stat'},    
}

const menu	= {
	'pathologist'		: [ NAV.patient, NAV.requisition, NAV.specimen, NAV.report, NAV.slide, NAV.user, NAV.department, NAV.stat ],
	'cytotechnologist'	: [ NAV.requisition , NAV.specimen , NAV.report , NAV.slide , NAV.stat ],
    'scientist'         : [ NAV.requisition , NAV.specimen , NAV.report , NAV.slide , NAV.stat ],
	'officer'			: [ NAV.requisition , NAV.specimen , NAV.report , NAV.slide , NAV.stat ],
    'doctor'			: [ NAV.requisition , NAV.viewer],
    'other'			    : [ NAV.requisition , NAV.viewer],	    
    'nurse_gyne'        : [ NAV.requisition , NAV.viewer],
    'med_student'       : [ NAV.requisition ],
    'nurse'             : [ NAV.requisition ],
    'oncologist'		: [ NAV.requisition , NAV.viewer ],
}


import axios from 'axios'
import LoginView from './components/Login.vue'



export default {
  	components:{
    	LoginView
    },

    data() {
		return {
            menuForRole : menu,
			visible: false,
		}
    },

  	computed : {
        hasSession(){
            let user    = this.$store.state['session'].user
            if(user){                 
                this.$store.dispatch('department/list')
			    this.$store.dispatch('user/list')
            }

            if(this.$store.state['session'].user == false && this.$route.path != '/'){
                this.$router.replace({ path: '/' })
            }

            return Boolean(this.$store.state['session'].user)
        },

    	hasPermission(){
            let user    = this.$store.state['session'].user
            
            if(!Boolean(user)) return false			            
            if(['pathologist','cytotechnologist','scientist','officer'].indexOf(user.role) == -1) return false
            
            this.$store.dispatch('department/list')
			this.$store.dispatch('user/list')
            
			return true
        },

        session(){            
            return this.$store.state['session']
        }
	},
	      
    mounted(){
        setInterval(() => {
            if(!this.hasSession)  return
            axios.get(`/api/session`).then( r => {
                if(!r.data.userId){
                    this.$store.state['session'].user   = false
                }
            })
        },60*1000)
    },
  	methods: {
		confirmLogout(){      
			this.visible = false
			this.logOut()
        },
        
		logOut(){
			axios({
                method : 'DELETE',
                url : `/api/session`,
            })
			.then( res => {
				this.$store.dispatch('session/login',false)
			})
			.catch()  
        },
        
		showModal() {
			this.visible = true
        },
        
  	}
}
</script>

<style>

.router-link-active{
    border-bottom: solid white 3px;
    font-weight: bolder;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}

#nav {
    a {
        font-weight: bold;
        font-size: 1.2rem;
        color: white;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.nav-item{
  margin-right : 10px;
}

</style>