<template>
    <b-modal ref="confirm-modal" size="lg" title="Patient" ok-variant="warning" ok-title="Confirm">
        <b-form-group class="my-3">
            <b-input-group>
                <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                    <b-dropdown-item @click="search.by=$event.srcElement.text" v-for="option in search.options" :key="option">{{option}}</b-dropdown-item>
                </b-dropdown>
                <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
                <b-input-group-append>
                    <b-button size="sm" @click="searchPatient">
                        <i class="fas fa-search"></i>Search
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>

        <!-- Main table element -->
        <b-table striped hover :items="patients" :fields="fields">
            <template v-slot:cell(actions)="row">
                <a href="#" @click.prevent="select(row.item)">Select</a>
            </template>
            <template v-slot:cell(name)="row">
                {{row.item.title}} {{row.item.name}} {{row.item.surname}}
            </template>
            <template v-slot:cell(createBy)="row">
                {{usernameById(row.item.createBy)}}
            </template>
            <template v-slot:cell(birthDate)="row">
                <span v-if="row.item.birthDate">
                    {{(new Date().getYear() + 1900) - parseInt(row.item.birthDate.substr(0,4))}}
                </span>
            </template>
        </b-table>

        <b-pagination v-model="paginate.page" :total-rows="paginate.counts" :per-page="paginate.pagesize" align="fill" />

    </b-modal>
</template>

<script>

import axios from 'axios'

export default {
    data() {
        return {
            confirmPass: '',
            authenState: '',
            promise: null,
            resolve: null,

            search: {
                options: ["hn","name","cid"],
                keyword: "",
                by: "hn"
            },

            fields: [
                { key: "cid", label: "CID" },
                { key: "hn", label: "HN" },
                { key: "name", label: "Patient Name" },
                { key: "birthDate", label: "Age" },
                { key: "actions", label: "Actions" }
            ],
            paginate: { counts: 0, page: 1, pagesize: 30 },
            patients: [],

        }
    },

    methods: {
        select(patient) {
            this.$emit('select',patient)
            this.$refs['confirm-modal'].hide()
        },

        async searchPatient() {
            let query   = this.search.keyword.length ? `?${this.search.by}=${this.search.keyword}` : "";
            query       = (query.length)? query + '&pagesize=10' : "?pagesize=10"

            axios.get(`/api/patients${query}`)
                .then(r => this.patients = r.data)
        },

        show() {
            axios.get(`/api/patients?pagesize=10`).then(r => this.patients = r.data)
            axios.get(`/api/patients/count`).then(r => {
                this.paginate.counts = r.data.counts
                this.paginate.pagesize = r.data.pagesize
            })
            this.$refs['confirm-modal'].show()

            // var self = this
            // this.authenState = ''
            // this.confirmPass = ''        
            // this.promise = new Promise(function (resolve, reject) { self.resolve = resolve })
            // return this.promise
        },

    }
}
</script>