<template>
    <b-container>
        <b-row class="px-3 mb-3">
            <h2 class="my-3">Specimens</h2>
            <div class="text-right ml-auto my-auto">
                <b-form-group class="mb-0">
                    <b-input-group>
                        <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                            <b-dropdown-item @click="search.by=$event.srcElement.text.trim()" v-for="option in search.options" :key="option">{{option}}
                            </b-dropdown-item>
                        </b-dropdown>
                        <template v-if="search.by == 'check-in' || search.by == 'date of collect'">
                            <b-input type="date" placeholder="start" v-model="search.start" />
                            <b-input type="date" placeholder="end" v-model="search.end" />
                        </template>
                        <template v-if="!(search.by == 'check-in' || search.by == 'date of collect') && search.by != 'pathologist'">
                            <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
                        </template>

                        <template v-if="search.by == 'pathologist'">                        
                                <select v-model="search.pathologist">
                                    <option v-for="doctor in pathologists" :key="doctor._id" :value="doctor._id">{{doctor.name}}</option>
                                </select>
                                <!-- <b-form-input list="pathologists-name-list" @change="selectPathologist" :value="pathologist.name" :disabled="!this.pathologists.length"></b-form-input>
                                <datalist id="pathologists-name-list">
                                    <option v-for="doctor in pathologists" :key="doctor._id">{{doctor.name}}</option>
                                </datalist> -->

                        </template>

                        <b-input-group-append>
                            <b-button size="sm" @click="searchData"><i class="fas fa-search"></i>Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-row>
        <b-table striped hover :items="specimens" :fields="fields">
            <template v-slot:cell(check-in)="row">
                {{new Date( parseInt( row.item._id.substr(0,8), 16 ) * 1000).toISOString().substr(0,10)}}
                <i class="fas fa-star-of-life text-warning" v-if="row.item.important"></i>
            </template>
            <template v-slot:cell(number)="row">
                <router-link :to="{ name: 'SpecimenInfo', params: {id:row.item._id} }">
                    {{row.item.number.substr(0,3)}}-{{row.item.number.substr(3,4)}}
                </router-link>
            </template>
            <template v-slot:cell(type)="row">
                {{typeByKey[row.item.type]}} <b class="text-info">{{row.item.dataForm && row.item.dataForm.outlab.estimateDate}}</b>
            </template>
            <template v-slot:cell(doctor)="row">
                {{doctorById(row.item.doctor)}}
            </template>
            <template v-slot:cell(patient)="row">
                <span v-if="row.item.patient">{{row.item.patient.title}} {{row.item.patient.name}} {{row.item.patient.surname}}</span>
            </template>
            <template v-slot:cell(pathologist)="row">
                <router-link :to="{ name: 'ReportInfo', params: {id:row.item.report._id} }" v-if="row.item.report">
                    <span>{{doctorById(row.item.pathologist)}}</span>
                    <span v-if="row.item.report.status == 'Pending'">(P)</span>
                    <span v-if="row.item.report.status == 'Confirm'">(C)</span>
                </router-link>
                <span v-else>{{doctorById(row.item.pathologist)}}</span>
            </template>
        </b-table>
        <b-pagination v-model="paginate.page" :total-rows="paginate.counts" :per-page="paginate.pagesize" align="fill" />
    </b-container>
</template>
<script>

import { mapState } from 'vuex'
import axios from "axios"

export default {
    name: 'Specimens',

    data() {
        return {
            search: {
                options: ["name", "number", "hn", "check-in", "date of collect", "pathologist"],
                keyword: "",
                start: '',
                end: '',
                by: "hn",
                pathologists : {},
            },
            query: {},
            specimens: [],
            paginate: { counts: 0, page: 1, pagesize: 30 },
            fields: ['check-in', 'number', { key: 'patient.hn', label: 'HN' }, { key: 'patient', label: 'Patient Name' }, 'type', 'doctor', 'pathologist'],
            typeByKey: {
                aoutlab: "Out lab",
                cellblock: "Cell-block cytology",
                nongyne: "Non-gyne cytology",
                papsmear: "Pap smear",
                slidereview: "Slide review",
                tissueorgan: "Tissue/organ specimen",
                liquidbase: "Liquid-based cyto.(Gyne.)",
                frozen: "Frozen Section"
            }
        }
    },

    mounted() {        
        this.checkRole()
        axios.get(`/api/specimens/`).then(res => {
            this.specimens = res.data
            this.paginate.counts = res.headers.counts
            this.paginate.pagesize = res.headers.pagesize
        })
    },

    computed: {
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),

        pathologists(){
            return this.users.filter( u => u.role == 'pathologist')
        },

        pathologist(){
            let doctor  = this.users.find(d => d._id == this.search.pathologist)
            if(!doctor) return {name:'',title:''}

            let part    = doctor.name.split('.')
            return {name:part[1],title:part[0]}
        },
    },

    watch: {
        $route (to, from){
            this.checkRole()
        },

        'paginate.page': function (page) {
            this.query.page = page
            let queries = Object.keys(this.query).map(q => q + '=' + this.query[q])

            axios
                .get('/api/specimens/?' + queries.join('&'))
                .then(r => this.specimens = r.data)
        }
    },

    methods: {
        checkRole(){
            const allow_role    = ['pathologist','cytotechnologist','scientist','officer']
            if(this.$store.state.session.user.role && !allow_role.includes(this.$store.state.session.user.role)){
                alert('Not allow')
                this.$router.replace({ path: '/' })
            }
        },

        selectPathologist(){

        },

        doctorById(id) {
            let doctor = this.users.find(d => d._id == id) || { name: '' }
            return doctor.name.split(' ')[0]
        },

        searchData() {
            this.query = {}

            if (this.search.by == 'pathologist' && this.search.pathologist)
                this.query = { pathologist: this.search.pathologist }

            if (this.search.by == 'name' && this.search.keyword.length)
                this.query = { name: this.search.keyword }

            if (this.search.by == 'hn' && this.search.keyword.length)
                this.query = { hn: this.search.keyword }

            if (this.search.by == 'number' && this.search.keyword.length)
                this.query = { number: this.search.keyword }

            if (this.search.by == 'check-in')
                this.query = { 'daterange': 'checkin', 'start': this.search.start, 'end': this.search.end }

            if (this.search.by == 'date of collect')
                this.query = { 'daterange': 'collect', 'start': this.search.start, 'end': this.search.end }

            let queries = Object.keys(this.query).map(q => q + '=' + this.query[q])

            axios
                .get('/api/specimens?' + queries.join('&'))
                .then(res => {
                    this.specimens = res.data
                    this.paginate.counts = res.headers.counts
                    this.paginate.pagesize = res.headers.pagesize
                })
        },

    }
}
</script>

<style scoped>
td > a {
    font-family: monospace;
    font-size: 1.25rem;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>