<template>
    <b-container>
        <div class="form-inline my-3">
            <h2 class="mb-0 d-inline">Record Specimen</h2>
            <b-button
                class="ml-3"
                variant="primary"
                @click="$router.push('/report/' + specimenForm.report._id)"
                v-if="specimenForm.report && specimenForm.report._id"
            >Open Report
            </b-button>

            <!-- No Report Create -->
            <template v-if="!specimenForm.report && specimenForm._id">
                <b-button
                    class="ml-3"
                    variant="outline-primary"
                    @click="$router.push('/report/specimen/' + specimenForm._id)"
                    v-if="!specimenForm.nonstat"
                >Create Report</b-button>
                <b-button
                    class="ml-3"
                    variant="outline-danger"
                    @click="confirmDelete"
                    v-if="canDelete"
                >Delete</b-button>
            </template>

            <div class="ml-auto d-flex">
                <b-alert
                    fade
                    :show="alert.countDown"
                    @dismissed="alert.countDown=0"
                    variant="warning"                     
                    class="d-inline mb-0 py-1 mr-3" 
                >
                {{alert.message}}
                </b-alert>
                <b-form-checkbox v-model="specimenForm.nonstat" class="my-auto mr-2">Non Stat</b-form-checkbox>
                <b-form-checkbox v-model="specimenForm.important" class="my-auto mr-2">Mark as important</b-form-checkbox>
                <b-button 
                    :variant="((needSave)?'':'outline-') + 'success'" 
                    class="ml-2" 
                    @click="save" 
                    :disabled="(specimenForm.report && specimenForm.report.status=='Confirm')" 
                >
                    Save
                </b-button>
            </div>        
        </div>
        <b-form>
            <SpecimenInfo :specimen="specimenForm" :patient="patient" :disabled="specimenForm.report && specimenForm.report.status=='Confirm'"/>
            
            <OutlabForm :lab="specimenForm.dataForm.outlab" v-if="specimenForm.type=='aoutlab'"/>                
            
            <!-- Sub Type  -->
            <div v-if="specimenForm.type == 'nongyne' ">
                <b-row>
                    <b-col cols="6">
                        <div class="form-inline">
                            <label class="mr-2">Total Slide :</label>
                            <b-form-input type="number" v-model.number="specimenForm.dataForm.totalSlide"></b-form-input>
                        </div>
                    </b-col>
                </b-row>
                <b-row >
                    <b-col>
                        <b-form-group label=" ">
                            <b-form-radio name="subtype-radios" v-for="type in subtypeOther"  :key="'opt_'+type" v-model="specimenForm.dataForm.subType" :value="type">{{type}}</b-form-radio>
                            <b-form-radio name="subtype-radios" value="Other" @change="specimenForm.dataForm.subType ='other'">Other</b-form-radio>                        
                            <b-input class="mt-2" v-model="specimenForm.dataForm.subType" v-show="showOtherField"></b-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Body fluid">
                            <b-form-radio name="subtype-radios" v-for="type in subtypeBodyFluid" :key="'opt_'+type" v-model="specimenForm.dataForm.subType" :value="type">{{type}}</b-form-radio>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="FNA">
                            <b-form-radio name="subtype-radios" v-for="type in subtypeFNA" :key="'opt_'+type" v-model="specimenForm.dataForm.subType" :value="type">{{type}}</b-form-radio>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>

            <!-- Container Slide Count -->
            <b-row v-if="['tissueorgan','cellblock','frozen'].indexOf(specimenForm.type) != -1">
                <b-col cols="5">
                    <b-row>
                        <b-col><h4 class="mb-3">Summary</h4></b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group  label="Total Slide:" >
                                <b-form-input :value="totalSlide" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group  label="Paraffin block (Last record)" class="text-info" >
                                <b-form-input :value="paraffinCount" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group  label="Recut: " >
                                <b-form-input :value="totalRecut" disabled></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group  label="Decalcification: " >
                                <b-form-input v-model="specimenForm.dataForm.decal" />
                            </b-form-group>
                        </b-col>                    
                    </b-row>
                </b-col>

                <!-- Containers -->
                <b-col cols="7">
                    <b-row>
                        <b-col cols="3" >
                            <h4 class="my-auto">Containers</h4>
                        </b-col>
                        <b-col cols="3">
                            <b-button class="w-25" size="sm" vpariant="secondary" @click="(containerChars < 10) && containerChars++">+</b-button>
                            <b-button class="w-25 ml-2" size="sm" variant="secondary"    @click="removeContainerChar">－</b-button>
                        </b-col>
                        <b-col cols="6" class="text-right">
                            <b-button size="sm" variant="primary" class="ml-2" @click="addContainer">Add</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="2" v-for="char in containerChars" :key="'char' + char" class="pr-0">
                            <b-input-group :prepend="String.fromCharCode(64 + char)" size="sm" class="mt-3">
                                <b-form-input type="number" min="1" max="99" v-model.number="containerForm[String.fromCharCode(64 + char)]"></b-form-input>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-table striped hover  :fields="containerFields" :items="specimenForm.dataForm.containers" class="mt-3">
                        <template v-slot:cell(date)="row">
                            <span :class="{'text-info': row.index == specimenForm.dataForm.containers.length -1 }">{{row.item.date}}</span>
                        </template>
                        <template v-slot:cell(user)="row">
                            <span :class="{'text-info': row.index == specimenForm.dataForm.containers.length -1 }">{{usernameById(row.item.user)}}</span>                            
                        </template>
                        <template v-slot:cell(slide)="row">
                            <div style="font-size:1.2rem">                                
                                <span v-for="letter in (Object.keys(row.item).length - 2)" :key="'l_'+letter" class="mx-3">
                                    <b-badge variant="dark">{{String.fromCharCode(64+letter)}}</b-badge>
                                    <b-badge variant="light">{{row.item[String.fromCharCode(64+letter)]}}</b-badge>
                                </span>
                            </div>
                        </template>
                        <template v-slot:cell(action)="row">
                            <b-button variant="danger" size="sm" class="float-right" @click="removeContainerAt(row.index)">－</b-button>
                        </template>
                    </b-table>
                </b-col> 
            </b-row>
            
            
            <b-row class="mt-3" v-if="['cellblock','tissueorgan'].indexOf(specimenForm.type) != -1">
                <!-- Recut Logs -->
                <b-col cols="5">
                    <b-row class="mb-2">
                        <b-col cols="7">
                            <h4 class="my-auto">Recut/Deeper cut</h4>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group >
                                <b-form-input size="sm" type="text" v-model="newRecutAmount"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group>
                                <b-button size="sm" variant="primary" @click="recut">Recut</b-button>
                            </b-form-group>
                        </b-col>
                    </b-row>                
                    <b-table striped hover :items="specimenForm.dataForm.recuts">
                        <template v-slot:cell(user)="row">
                            {{usernameById(row.item.user)}}
                        </template>
                    </b-table> 
                </b-col>

                <!-- Special Stain -->
                <b-col>                
                    <b-row class="mb-4">
                        <b-col cols="4">
                            <h4 class="my-auto">Special Stain</h4>
                        </b-col>
                        <b-col class="pr-0">
                            <b-input-group size="sm" append="AFB">
                                <b-input type="number" v-model.number="stainForm.afb"/>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <b-input-group size="sm" append="GMS">
                                <b-input type="number" v-model.number="stainForm.gms"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col class="pl-0">
                            <b-input-group size="sm" append="PAS">
                                <b-input type="number" v-model.number="stainForm.pas"></b-input>
                            </b-input-group>
                        </b-col>
                        <b-col cols="2" class="text-right">
                            <b-button size="sm" variant="primary" @click="addStainLog">Add</b-button>
                        </b-col>
                    </b-row>
                    <b-table striped hover :items="specimenForm.dataForm.stains">
                        <template v-slot:cell(user)="row">
                            {{usernameById(row.item.user)}}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-form>
        <hr/>
        <h4>Logs</h4>
        <div class="w-75">
            <div class="row px-3" v-for="(log,index) in specimenForm.logs" :key="index">
                <span class="w-25">{{new Date(log.date).toLocaleString('en-GB')}}</span>
                <span class="w-25"><b>{{log.action}}</b></span>
                <span class="w-25">{{usernameById(log.user)}}</span>
            </div>
        </div>
        <b-row class="my-5"></b-row>
        <AuthenModal ref="authen-modal"/>
    </b-container>
</template>

<script>

import { mapState } from 'vuex'
import axios from 'axios'

import OutlabForm from '../components/OutlabForm.vue'
import SpecimenInfo from '../components/SpecimenInfo.vue'

import AuthenModal from '../components/AuthenModal.vue'
import RequisitionFormVue from './RequisitionForm.vue'


let outlabDefault  = {
    type            : null,
    consultation    : {},
    specimen        : {},
    genetic         : [],
    stain           : [],
} 

export default {
    components:{
        OutlabForm,SpecimenInfo,AuthenModal
    },

    data() {
        return {
            specimenForm : {
                number      : '',                
                type        : '',                                
                doctor      : null,
                department  : null,
                patient     : null,
                dataForm    : {
                    outlab      : outlabDefault,
                    containers  : [],
                    recuts      : [],
                    stains      : [],
                },
                report      : {status : ''},
                
                requisitionId : null,
            },
            labForm : {type:'',consultation:{},specimen:{},immuhc:'',genetic:[],genetic_other:'',stain:[],stain_other:'',other:''},
            containerFields: [                        
                { key: 'date', label: 'Date' },
                { key: 'user', label: 'User'},
                { key: 'slide', label: 'Slide' },
                { key: 'action', label: '' },
            ],
            subtypeBodyFluid: ['Pleural fluid','Pericardial fluid','Peritoneal fluid/Ascites','Peritoneal washing','Cerebrospinal fluid'],
            subtypeFNA      : ['Lymph node','Thyroid gland','Salivary gland','Neck mass','Breast'],
            subtypeOther    : ['Bronchial brushing','Bronchial washing','Urine','Sputum'],
            containerChars  : 1,
            containerForm   : {A:1},
            newRecutAmount  : 0,
            stainForm   : {afb:0,gms:0,pas:0},
            patient     : {},
            reportId    : null,
            alert       : {message : '',countDown :0},
            needSave    : false,
        }
    },
    computed: {
        ...mapState('user',{users: state => state.data}),

        canDelete(){
            return this.$store.state['session'].user.role == 'pathologist'
        },

        showOtherField(){
            return [...this.subtypeBodyFluid,...this.subtypeFNA,...this.subtypeOther].indexOf(this.specimenForm.dataForm.subType) == -1
        },

        totalRecut(){
            return this.specimenForm.dataForm.recuts.reduce((s,n) => s+n.amount,0)
        },

        totalSlide(){            
            let stains          = this.specimenForm.dataForm.stains
            let {afb,gms,pas}   = (stains.length)? stains[stains.length-1] : {afb:0,gms:0,pas:0}
            this.specimenForm.dataForm.totalSlide    = this.totalRecut + this.paraffinCount + Number(afb) + Number(gms) + Number(pas)

            return this.specimenForm.dataForm.totalSlide
        },

        paraffinCount(){
            let containerCount      = this.specimenForm.dataForm.containers.length
            if(!containerCount)   return 0

            let sum         = 0
            let self        = this                
            var c           = this.specimenForm.dataForm.containers[containerCount - 1]

            let keyCount    = Object.keys(c).length - 2                         
            for(let i = 0;i < keyCount;i++){
                sum += c[String.fromCharCode(i+65)]
            }

            return sum
        },
  
    },
    async mounted() {
        this.checkRole()

        let thisView    = this
        let dataForm    = this.specimenForm.dataForm

        if(this.$route.params.patient){
            this.specimenForm.patient   = this.$route.params.patient._id
            this.patient                = this.$route.params.patient
        }
       
        if(this.$route.name == 'NewSpecimen'){            
            this.specimenForm.patient   = this.$route.params.id

            if(this.$route.params.requisitionId){
                this.specimenForm.requisitionId = this.$route.params.requisitionId
                this.specimenForm.date          = this.$route.params.date
                this.specimenForm.doctor        = this.$route.params.doctor
                this.specimenForm.department    = this.$route.params.department
                this.specimenForm.type          = this.$route.params.type
            }
            return
        }

        //For SpecimenInfo
        fetch(`/api/specimens/${this.$route.params.id}`,{credentials: 'include'})
        .then(res   => res.json())
        .then(json  => {
            this.specimenForm   = json
            if(!dataForm.outlab.consultation)  dataForm.outlab.consultation     = {}
            if(!dataForm.outlab.specimen)      dataForm.outlab.specimen         = {}
        })
    },

    watch: {
        $route (to, from){
            this.checkRole()
        },
    },

    methods:{
        checkRole(){
            const allow_role    = ['pathologist','cytotechnologist','scientist','officer']
            if(this.$store.state.session.user.role && !allow_role.includes(this.$store.state.session.user.role)){
                alert('Not allow')
                this.$router.replace({ path: '/' })
            }
        },

        confirmDelete(){
            this.$refs['authen-modal'].show().then( res => {                
                axios.delete(`/api/specimens/${this.specimenForm._id}`,this.specimenForm)            
                .then(data => {                
                    this.$router.replace(`/specimen`)
                })
                .catch( err => {
                    this.alert.message      = err.response.data.message || err.message
                })
            })
        },

        usernameById(id){
            const user    = this.users.find( u => u._id === id)
            return user && user.username
        },

        save(input){
            let method      = (this.specimenForm._id)? 'PUT' : 'POST'
            let thisView    = this
            let path        = (this.specimenForm._id)? this.specimenForm._id : ''
            let dataForm    = this.specimenForm

            let btn         = event.srcElement
            btn.disabled = true

            axios({
                method,
                url : `/api/specimens/${path}`,
                data  : this.specimenForm,
                withCredentials: true
            })
            .then(r => r.data)
            .then(data => {                
                thisView.specimenForm   = data
                if(!thisView.specimenForm.dataForm.outlab.consultation)  thisView.specimenForm.dataForm.outlab.consultation     = {}
                if(!thisView.specimenForm.dataForm.outlab.specimen)      thisView.specimenForm.dataForm.outlab.specimen         = {}
                                
                thisView.needSave           = true            
                thisView.alert.message      = 'Save done'

                if(method == 'POST'){
                    this.$router.replace('/specimen/' + data._id)
                }
            })
            .catch( err => {
                thisView.alert.message      = err.response.data.message || err.message
            })
            .finally( a => {
                thisView.alert.countDown    = 5
                btn.disabled                = false
            })
        },

        goToReport(){
            let url = (this.specimenForm.report)? this.specimenForm.report._id : 'specimen/' + this.specimenForm._id

            if(this.specimenForm.report){
                return this.$router.push('/report/'+ this.specimenForm.report._id)
            }


            this.$router.push('/report/'+ url)
        },

        addStainLog(){
            this.specimenForm.dataForm.stains.push({
                date: new Date().toISOString().substr(0,10),
                user: this.$store.getters["session/user"].userId,
                ...this.stainForm
            })
        },

        addContainer(){
            let userId      = this.$store.getters["session/user"].userId
            this.specimenForm.dataForm.containers.push({ 
                date: new Date().toISOString().substr(0,10), 
                user: userId, 
                ...this.containerForm
            })
        },

        removeContainerAt(index){
            this.specimenForm.dataForm.containers.splice(index,1)
        },
        
        removeContainerChar(){
            if(!this.containerChars) return
            delete this.containerForm[String.fromCharCode(64 + this.containerChars--)]            
        },

        recut(){
            this.specimenForm.dataForm.recuts.push({
                date: new Date().toISOString().substr(0,10),
                user: this.$store.getters["session/user"].userId,
                amount: parseInt(this.newRecutAmount)
            })
        }
    }

}

</script>

<style scoped>

.input-group-text{
    font-family: monospace;
    font-weight: bold;
    font-size: 1rem !important;
    /* padding-left: 6px !important;
    padding-right: 6px !important; */
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

</style>
