<template>
    <div class="mb-2">
        <b-button-group class="my-2" v-if="!disabled">
            <b-button @click="applyStyle('bold')"><i class="fas fa-bold"></i></b-button>
            <b-button @click="applyStyle('italic')"><i class="fas fa-italic"></i></b-button>
            <b-button @click="applyStyle('underline')"><i class="fas fa-underline"></i></b-button>
            <b-button @click="applyStyle('strikeThrough')"><i class="fas fa-strikethrough"></i></b-button>
            <b-button @click="applyStyle('indent')" variant="info"><i class="fas fa-indent"></i></b-button>
            <b-button @click="applyStyle('outdent')" variant="info"><i class="fas fa-outdent"></i></b-button>
            <b-button @click="applyStyle('insertUnorderedList')" variant="info"><i class="fas fa-list-ul"></i></b-button>
            <b-button @click="applyStyle('insertOrderedList')" variant="info"><i class="fas fa-list-ol"></i></b-button>
            <b-button @click="applyStyle('h1')" variant="dark"><b>H1</b></b-button>
            <b-button @click="applyStyle('h2')" variant="dark"><b>H2</b></b-button>
            <b-button @click="applyStyle('h3')" variant="dark"><b>H3</b></b-button>

            <b-button @click="applyStyle('p')" variant="dark"><b>P</b></b-button>
            
            <b-button @click="applyStyle('undo')" variant="light"><i class="fas fa-undo"></i></b-button>
            <b-button @click="applyStyle('redo')" variant="light"><i class="fas fa-redo"></i></b-button>

        </b-button-group>
        <div
            class="border rounded text-editor-area p-2"
            style="min-height:300px;white-space: initial;" 
            ref="texteditor"
            :contenteditable="!disabled"
            @keydown="keydown"
            @keyup="keyup"
            @paste="onPaste"
        />
    </div>
</template>

<script>

var someError = false;
var tagsAllowed = "|h1|h2|h3|h4|p|a|b|u|i|strong|strike|br|ol|ul|li|sub|sup|font|img|";
var emptyTagsAllowed = "|br|hr|img|";
var attributesAllowed = new Array();
attributesAllowed["a"] = "|href|";
attributesAllowed["img"] = "|src|";
// attributesAllowed["font"] = "|color|";

attributesAllowed["font"] = "||";

function cleanUpTag(obj) {
    var selfFn = cleanUpTag    
    
    Array.from(obj.children).forEach(function(el) {
        //recursively down the tree
        selfFn(el)

        var tag = el.tagName.toLowerCase()
        if (tagsAllowed.indexOf("|" + tag + "|") < 0) {            
            let span        = document.createElement('span')
            span.innerHTML  = el.innerHTML
            return el.replaceWith(span);
        }

        if (el.innerHTML.replace(/^\s+|\s+$/g, '') == "" && emptyTagsAllowed.indexOf("|" + tag + "|") < 0) {
            return el.remove();
        }

        Array.from(el.attributes).forEach(function(attr) {
            if (attributesAllowed[tag] == null || attributesAllowed[tag].indexOf("|" + attr.name.toLowerCase() + "|") < 0) {
                el.removeAttribute(attr.name);
            }
        })

        if(tag == 'img'){
            el.setAttribute('class','img-fluid')
        }
    })
}


export default {
    props : ['content','field','disabled','user'],

    mounted(){
        this.$refs['texteditor'].innerHTML  = this.content[this.field] || ''
    },

    methods: {
        keyup(input){
            if(event.key == 'Tab')  event.preventDefault()
            this.$nextTick( () => {
                this.content[this.field]                = this.$refs['texteditor'].innerHTML
                this.content[this.field + '_user']      = this.user
            })            
        },

        keydown(input){
            if(event.key == 'Tab')  event.preventDefault()
        },

        onPaste (evt) {
            event.preventDefault()            
            var cbhtml      = event.clipboardData.getData("text/plain")
            var div         = document.createElement('div')
            div.innerHTML   = cbhtml

            cleanUpTag(div)
            document.execCommand("insertHTML", false, div.innerHTML.replace(/&nbsp;/g, '').replace(/<p><\/p>/g, ''));
            this.content[this.field]    = event.srcElement.innerHTML
        },

        updateText(){
            this.$refs['texteditor'].innerHTML      = this.content[this.field]             
        },

        applyStyle(style){            
            if(['h1','h2','h3','p'].indexOf(style) != -1){
                document.execCommand('formatBlock', false, style)
            }
            else{
                document.execCommand(style, false, null)
            }            
        }
    }
}
</script>

<style scoped>
pre {
    display: flex;
    white-space: normal;
    word-break: break-word;
}
</style>