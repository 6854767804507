<template>
    <b-container>
        <div class="d-flex my-3">
            <h3 class="my-auto mr-3">Department</h3>
            <b-button @click="departmentFormModal">Create</b-button>
            <b-input-group class="ml-auto w-50">
                <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                <b-dropdown-item
                    @click="search.by=$event.srcElement.text"
                    v-for="option in search.options"
                    :key="option"
                >{{option}}</b-dropdown-item>
                </b-dropdown>
                <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
            </b-input-group>    
        </div>
        
        <b-table striped hover :items="departmentsFilter" :fields="fields" >
            <template v-slot:cell(action)="row">
                <a href="#" @click="departmentFormModal(row.item)">Edit</a>
            </template>
        </b-table>
        <b-modal ref="dept-modal" title="User Info" @ok="save">
            <b-row>
                <b-col>
                    <b-form-group label="Name :">
                    <b-form-input v-model="departmentForm.name"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>
<script>

import axios from "axios"
import { mapState } from 'vuex'

export default {
    data(){
        return {
            departments : [],
            departmentForm : {},
            fields : ['name','action'],
            formIndex : null,
            search: {
                options: ["name"],
                keyword: "",
                by: "name"
            },
        }
    },

    computed : {
        departmentsFilter(){
            return this.departments
        }
    },

    // watch : {
    //     'search.keyword': function(value){
    //         let by              = this.search.by
    //         this.usersFilter    = this.users.filter( u => u[by] && u[by].search(value) != -1 )
    //     }
    // },

    mounted(){
        axios
        .get('/api/departments')
        .then( res => {
            this.departments    = res.data
        })
    },

    methods : {
        deptName(deptId){
            return (this.departments.find(d => d._id == deptId) || {name:''}).name
        },

        selectDepartment(input){
            let dept                    = this.departments.find( d => input === d.name) || {}
            this.userForm.department    = dept._id || null
        },
        
        departmentFormModal(item){
            event.preventDefault()
            
            this.formIndex          = this.departments.indexOf(item)
            this.departmentForm     = {...item}

            console.log(this.formIndex,item)
            this.$refs["dept-modal"].show()
        },

        save(bvModalEvt){
            bvModalEvt.preventDefault();
            
            axios({
                method  : this.departmentForm._id ? "PUT" : "POST",
                url     : `/api/departments/${(this.departmentForm._id)? this.departmentForm._id : ''}`,
                data    : this.departmentForm,
            })
            .then(res => {

                if(this.formIndex < 0)
                    this.departments.push(res.data)
                else                      
                    Object.assign(this.departments[this.formIndex],res.data)
                
                this.$refs["dept-modal"].hide()
            })
            .catch( err => {
                //this.alert.message      = err.response.data.message || err.message
            })
        }
    }
}
</script>