<template >
    <b-modal ref="modal" title="Select Specimen" ok-variant="warning" size="sm" hide-footer>
        <b-form-row>
            <b-col cols="12" class="pr-0 text-letter">
                <b-form-group label="Enter Specimen Number" :description="specimenStatus">
                    <b-form-input size="sm" maxlength="1" @input="numCode(0)" :value="number.charAt(0)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(1)" :value="number.charAt(1)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(2)" :value="number.charAt(2)" autocomplete="off"/>
                    <label class="text-center" style="min-width:12px">-</label>
                    <b-form-input size="sm" type="number" @input="numCode(3)" :value="number.charAt(3)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(4)" :value="number.charAt(4)" autocomplete="off"/>
                    <b-form-input size="sm" type="number" @input="numCode(5)" :value="number.charAt(5)" autocomplete="off"/>
                    <b-form-input size="sm" maxlength="1" @input="numCode(6)" :value="number.charAt(6)" autocomplete="off"/>                        
                    <span></span>
                </b-form-group>
            </b-col>
        </b-form-row>
        <div>
            <b-card v-for="specimen in specimens" :key="specimen._id"  :title="specimen.number + ' ' + specimen.type " :sub-title="specimen.date"  class="mb-3" >
                <b-card-text>HN {{specimen.patient.hn}} </b-card-text>
                <b-card-text><b>{{specimen.patient.title}}</b> {{specimen.patient.name}} {{specimen.patient.surname}}</b-card-text>
                <a href="#" class="card-link" @click="select(specimen)">Select</a>    
            </b-card>
        </div>
    </b-modal>
</template>

<script>

import axios from "axios"


export default {
    data(){
        return {
            index : 0,
            specimenStatus : '',
            specimens : [],
            number : '',
            specimen : {                
                number : ''
            },
            promise : null,
            resolve : null,
        }
    },

    watch : {
        'number' : function(newNumber){
            if(newNumber.length != 7) return

            this.loadSpecimenInfo()
            console.log('load new number',newNumber)
        }
    },

    methods : {
        select(specimen){
            event.preventDefault()
            this.resolve(specimen)
            this.$refs.modal.hide()
        },

        show(){
            var self            = this
            this.promise        = new Promise(function(resolve, reject) { self.resolve = resolve })
            this.$refs.modal.show()

            return this.promise
        },

        numCode(index){
            if(!event.data) return
            event.srcElement.value  = event.data.toUpperCase()
            let no                  = this.number
            this.number    = no.substr(0,index) + event.srcElement.value + no.substr(index+1)

            
            let next    = event.srcElement.nextElementSibling
            if(!next.select){
                next    = (next.nextElementSibling)? next.nextElementSibling : next
            }            
            next.select && next.select()
        },

        loadSpecimenInfo(){
            axios(`api/specimens?number=${this.number}`)
            .then(res  => {                                
                if(!res.data.length){
                    this.specimen.patient   = null
                    this.specimen.slide     = null
                    this.specimen.ref       = null
                    return this.specimenStatus     = 'Not found specimen'                
                }

                this.specimens      = res.data

                // this.specimenInfo       = res.data[0]                
                // this.specimen.ref       = this.specimenInfo._id
                
                // if(!this.specimen.slideNumbers)  this.specimen.slideNumbers = {}                
                // if(!this.specimen.paraffinNumbers)  this.specimen.paraffinNumbers = {}                
                
                // this.specimen.slide     = 1
                // this.specimen.patient   = this.specimenInfo.patient._id
                // this.specimenStatus     = `${this.specimenInfo.dataForm.totalSlide} slides and record on ${this.specimenInfo.date || '-'}`
            })
        },

        
    }
}
</script>

<style scoped>

.text-letter input{
    width: 34px;
    margin-right: 3px;
    font-size: 1rem;
    text-align: center;
    padding: 0px;
    display: inline-block;
}

.input-group-text{
    font-family: monospace;
    font-weight: bold;
    font-size: 1.1rem !important;
}

input::-webkit-inner-spin-button, 
input::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

</style>