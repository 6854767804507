<template>
    <b-container>
        <table :class="{'d-print-none':!canPrint}" style="width:100%">
            <thead>
                <tr>
                    <b-row class="mb-2 d-print-block d-none">
                        <b-col cols="12" class="text-center">
                            <img src="logo.png" style="max-width: 110px;position: absolute;left: 0;" />
                            <h3 class="mt-2 mb-1">Pathology report</h3>
                            <h5>Department of anatomical pathology, Yala regional hospital</h5>
                            <p class="mb-2">152 Siroros road, Sateng, Mueang, Yala 95000 Tel. (+66) 7324 4711-8 ext 2502</p>
                        </b-col>
                    </b-row>

                    <b-row class="mb-2 d-print-none">
                        <b-col cols="9" class="text-center">
                            <img src="logo.png" style="max-width: 120px;position: absolute;left: 0;" class="mt-3" />
                            <h3 class="mt-2 mb-1">Pathology report</h3>
                            <h5>Department of anatomical pathology, Yala regional hospital</h5>
                            <p class="mb-2">152 Siroros road, Sateng, Mueang, Yala 95000 Tel. (+66) 7324 4711-8 ext 2502</p>
                        </b-col>
                        <b-col cols="3">
                            <div class="text-right mt-2">
                                Version :
                                <select class="form-control" v-model="selectedIndex">
                                    <option v-for="(confirmation,index) in report.confirmed" :key="confirmation.date" :value="index" >{{new Date(confirmation.date).toLocaleString()}}</option>
                                </select>
                            </div>
                        </b-col>
                    </b-row>
                </tr>
                <tr>
                    <b-row class="border-top border-dark pt-2">
                        <b-col cols="4">Name : {{patientInfo.title}} {{patientInfo.name}} {{patientInfo.surname}}</b-col>
                        <b-col cols="2">Age : {{patientAge}}</b-col>
                        <b-col cols="4">HN : {{patientInfo.hn}}</b-col>
                        <b-col cols="2">Sex : {{patientInfo.sex}}</b-col>
                    </b-row>
                </tr>
                <tr>
                    <b-row>
                        <b-col cols="6">Doctor : {{doctor.title}}. {{doctor.name}}</b-col>
                        <b-col>Date of specimen collected : {{specimen.date}}</b-col>
                    </b-row>
                </tr>
                <tr>
                    <b-row>
                        <b-col cols="6">Department : {{deptName}}</b-col>
                    </b-row>
                </tr>
                <tr>
                    <b-row>
                        <b-col cols="6">Date of received : {{specimen && specimen._id && new Date( parseInt( specimen._id.substr(0,8), 16 ) * 1000).toISOString().substr(0,10)}} </b-col>
                        <b-col v-if="confirmed">Date of report : {{confirmed.date.substr(0,10)}} </b-col>
                    </b-row>
                </tr>
                <tr>
                    <b-row class="border-bottom border-dark mb-1 pb-1 ">
                        <b-col cols="6">{{userRoleById(confirmed.user)}} : {{nameById(confirmed.user)}}</b-col>
                        <b-col>Specimen number : {{specimen.number.substr(0,3)}}-{{specimen.number.substr(3,4)}}</b-col>
                    </b-row>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td>
                        <b-row>
                            <b-col cols="12" class="text-right"> {{userRoleById(confirmed.user)}} ................................................ </b-col>
                        </b-row>
                        <b-row class="border-top border-dark mt-1 pt-1">
                            <b-col>ผู้ยืนยันรายงานผล : {{nameById(confirmed.user)}}</b-col>
                            <b-col>วันเวลาที่ยืนยัน : {{confirmed.date.substr(0,10)}} {{new Date(confirmed.date).toLocaleTimeString('en-GB')}}</b-col>
                        </b-row>
                        <b-row class="border-bottom border-dark mb-1 py-1">
                            <b-col>ผู้พิมพ์รายงานผล : {{($store.state['session'].user || {}).username}}</b-col>
                            <b-col>วันเวลาที่พิมพ์ : {{createTime.toISOString().substr(0,10)}} {{createTime.toLocaleTimeString('en-GB')}}</b-col>
                        </b-row>
                        <div class="text-center">รายงานนี้รับรองผลเฉพาะตัวอย่างที่นำมาตรวจวิเคราะห์เท่านั้น มาตรา 265 ผู้ใดปลอมแปลงเอกสารรายการต้องระวางโทษจำคุก</div>
                        <div class="text-center">ตั้งแต่ 6 เดือน - 5 ปีและปรับตั้งแต่ 1,000 - 10,000 บาท ห้ามตัดทอนหรือคัดลอกเฉพาะบางส่วน</div>
                    </td>
                </tr>
            </tfoot>
            <tbody>
                <tr>
                    <td id="content">
                        <PapSmearForm :papsmear="reportSelectedVersion.papsmear" v-if="specimen.type=='papsmear'" :print="true" :disabled="true" />
                        <PapSmearForm :papsmear="reportSelectedVersion.liquidbase" v-if="specimen.type=='liquidbase'" :print="true" :disabled="true" />
                        <div v-if="['cellblock','slidereview','tissueorgan','frozen'].indexOf(specimen.type)!=-1" v-show="reportSelectedVersion.diagnosis && reportSelectedVersion.diagnosis.length > 2">
                            <p class="mb-1"><b>Diagnosis : </b></p>
                            <pre v-html="reportSelectedVersion.diagnosis" style="white-space: initial;" />
                        </div>
                        <template v-if="['cellblock','slidereview','tissueorgan','frozen'].indexOf(specimen.type)!=-1" v-show="reportSelectedVersion.gross && reportSelectedVersion.gross.length > 2">
                            <p class="mb-1"><b>Gross Examination :</b></p>
                            <div v-html="reportSelectedVersion.gross" style="white-space: initial;" />
                            <div class="text-right">{{reportSelectedVersion.editby}}</div>
                        </template>
                        <div v-if="specimen.type=='nongyne'">
                            <p class="mb-1"><b>Site : </b></p>
                            <pre v-html="reportSelectedVersion.site" style="white-space: initial;" />

                            <p class="mb-1"><b>Detail of specimen : </b></p>
                            <pre v-html="reportSelectedVersion.detail" style="white-space: initial;" />

                            <p class="mb-1"><b>Diagnosis : </b></p>
                            <pre v-html="reportSelectedVersion.diagnosis" style="white-space: initial;" />

                            <p class="mb-1"><b>Comment : </b></p>
                            <pre v-html="reportSelectedVersion.comment" style="white-space: initial;" />

                            <p class="mb-1"><b>Screener : </b></p>
                            <pre v-html="reportSelectedVersion.screener" style="white-space: initial;" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import PapSmearForm from '../components/PapSmearForm.vue'
import axios from 'axios'

export default {
    components: {
        PapSmearForm
    },
    data() {
        return {
            selectedIndex : 0,
            report: {
                specimen: '',
                confirmed: [],
                current: {
                    subtype: '',
                    papsmear: {},
                    liquidbase: {}
                },
            },
            specimen: { number: '', type: '' },
            patientInfo: {},
            subtypes: [
                { text: 'Unremarkable', value: 'Unremarkable' },
                { text: 'Reactive/inflammation', value: 'Reactive/inflammation' },
                { text: 'Benign tumor', value: 'Benign tumor' },
                { text: 'Borderline tumor', value: 'Borderline tumor' },
                { text: 'Malignancy', value: 'Malignancy' }
            ],
            specimenType: [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
            ],

        }
    },

    computed: {
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),

        canPrint() {
            return ['pathologist', 'cytotechnologist', 'oncologist', 'officer', 'scientist', 'other'].indexOf(this.$store.state['session'].user.role) != -1
        },

        doctor() {
            return this.users.find(d => d._id == this.specimen.doctor) || { name: '', title: '' }
        },

        deptName() {
            return (this.departments.find(d => d._id == this.specimen.department) || { name: '' }).name
        },

        patientAge() {
            if (!this.patientInfo.birthDate) return 0
            //return (new Date().getYear() + 1900) - parseInt(this.patientInfo.birthDate.substr(0,4))                        
            let diffTime = Math.abs(new Date - new Date(this.patientInfo.birthDate))
            let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

            if (days > 365)
                return Math.floor(days / 365) + ' years'
            if (days > 31)
                return Math.floor(days / 30) + ' months'

            return days + ' day'
        },

        confirmed() {
            if (!this.report.confirmed.length) return { date: '' }
            return this.report.confirmed[this.report.confirmed.length - 1]
        },

        createTime() {
            return new Date
        },

        reportSelectedVersion(){
            return this.report && this.report.confirmed[this.selectedIndex].data
        }
    },

    async mounted() {
        let thisView = this
        this.report = (await axios.get(`/api/reports/${this.$route.params.id}`)).data
        this.selectedIndex  = this.report.confirmed.length - 1
        
        axios
            .get(`/api/specimens/${this.report.specimen}`)
            .then(r => {
                thisView.specimen = r.data
                return axios.get(`/api/patients/${thisView.specimen.patient}`,)
            })
            .then(p => {
                thisView.patientInfo = p.data
            })
    },

    methods: {
        nameById(id) {
            const user = this.users.find(u => u._id === id)
            return user && user.name
        },

        userRoleById(id) {
            const user = this.users.find(u => u._id === id)
            const role = (user && user.role) || ' '

            return role.charAt(0).toUpperCase() + role.substr(1, role.length - 1)
        },
    }
}
</script>

<style scoped>
thead > tr {
    height: 25px;
}

@media print {
    #content:after {
        display: block;
        content: "";
        height: 350mm;
    }
}

@page {
    size: A4;
}

@page {
    @bottom-center {
        content: counter(page) "/" counter(pages);
    }
}

pre {
    border: 0;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;
    /* page-break-inside: avoid; */
}

h6 {
    font-size: 1.8rem;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}
</style>
