import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';

// Sentry.init({
//   dsn: 'https://a678ca81ca944e8ea3ce6a3bdc817831@o420601.ingest.sentry.io/5339096',
//   integrations: [new VueIntegration({Vue, attachProps: true})],
// });

import App from './App.vue'
import router from './router'
import store from './store.js'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')