<template>
    <div>
        <div class="form-inline mb-3">
            <h5 class="mt-1 mr-2">{{title}} </h5>
            <span class="d-none d-print-block"> ({{filter.year}} - {{filter.month.toString().padStart(2,'0')}}) {{filter.type}}</span>
            <hr/>
            <div class="form-inline ml-auto d-print-none">
                <div class="form-inline" v-show="showDepartments">
                    <label><b>Department :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.department" :options="departments"></b-form-select>                      
                </div>
                <div class="form-inline" v-show="typeSelector">
                    <label><b>Type :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.type" :options="specimenType"/>
                </div>
                <div class="form-inline mx-4">
                    <label><b>Year :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.year" :options="Array.from(Array(5)).map((e,i)=>i+2019)" />
                </div>
                <div class="form-inline ">
                    <label><b>Month :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.month" :options="Array.from(Array(12)).map((e,i)=>i+1)" />
                </div>
                <b-button size="sm" class="ml-2" variant="success" @click="reloadData">Refresh</b-button>
            </div>
        </div>
        <b-table striped hover sticky-header :items="items" :fields="fields"  ref="table" fixed style="min-height:600px">
            <template v-slot:table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: (field.key === 'name' || field.key === 'confirmUser' )? '160px' : '90px'}" 
                >
            </template>
        </b-table>
    </div>
</template>

<script>

import axios from 'axios'
import { mapState } from 'vuex'


export default {
    data(){
        return {
            title   : '',
            items   : [],
            filter  : {year : 2000,month:1,type:'papsmear'},
            fields  : ['date'],
            typeSelector : true,
            showDepartments : false,
            specimenType    : [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
                { value: 'aoutlab', text: 'Outlab' },
            ],
        }
    },
    
    computed: {
        ...mapState("department",{
            departments: state => state.data.map(row => new Object({'text':row.name, 'value': row._id}) )            
        }),


    },

    mounted(){
        let now                 = new Date
        this.filter.month       = now.getMonth() + 1
        this.filter.year        = now.getFullYear()
        this.reloadData()
    },

    watch : {
        '$route.path':{
            handler() {
                this.reloadData()
            },
        }
    },

    methods : {
        reloadData(){
            let path            = this.$route.path.split('/')
            this.typeSelector   = true 
            
            if(path[3] == 'subtype' && path[4] != 'byspecimen')     this.typeSelector   = false
            if(path[2] == 'papsmear' && path[3] == 'multi')         this.typeSelector   = false
            if(path[2] == 'material' && path[3] != 'all')           this.typeSelector   = false
            if(path[2] == 'papsmear' && path[3] == 'department')    this.typeSelector   = false

            this.showDepartments     = (path[2] == 'papsmear' && path[3] == 'department')            

            let query   = Object.keys(this.filter).map( q =>  q + '=' + this.filter[q] )

            axios.get('/api' + this.$route.path + '?' + query.join('&')).then( r => {
                this.title      = r.data.title
                this.items      = r.data.items
                this.fields     = r.data.fields
                this.$refs.table.refresh()
            })
        }
    }

}
</script>