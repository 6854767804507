<template>
    <b-container>
        <div class="form-inline">
            <h2 class="my-4 d-inline">Slide Rental</h2>
            <div class="ml-auto">
                <b-alert fade :show="alert.countDown" @dismissed="alert.countDown=0" variant="warning" class="d-inline py-2 mr-3">
                    {{alert.message}}
                </b-alert>
                <b-button :variant="((needSave)?'':'outline-') + 'success'" @click="save">Save</b-button>
            </div>
        </div>

        <!-- d-print-block d-none -->
        <div class="text-center mt-4">
            <h4>แบบฟอร์มการขอสไลด์พยาธิวิทยา/พาราฟินบล็อกชิ้นเนื้อ/ใบรายงานผล</h4>
            <h5>Department of Anatomical Pathology, Yala Hospital</h5>
            <h6>152 Siroros road, Sateng, Mueang, Yala 95000 Tel. (+66) 7324 4711-8 ext 187</h6>
            <p>(โปรดกรอกข้อมูลให้ครบถ้วนและชัดเจน)</p>
        </div>

        <b-row class="mt-3">
            <b-col cols="4" class="pr-1">
                <div class="doc-form d-flex">
                    <b class="mr-1" style="min-width:55px;">Staff :</b>
                    <b-form-input list="doctor-name-list" @change="selectDoctor" :value="(doctor.title || ' ') + ' ' + doctor.name" :disabled="!doctors.length || isDisabled"></b-form-input>
                    <datalist id="doctor-name-list">
                        <option v-for="doctor in doctors" :key="doctor._id">{{doctor.name}}</option>
                    </datalist>
                </div>
            </b-col>

            <b-col cols="3">
                <div class="doc-form d-flex">
                    <b class="mr-2" style="min-width:50px;">Ward :</b>
                    <b-form-input list="dept-list-id" @change="selectDepartment" :value="deptName" :disabled="!departments.length || isDisabled"></b-form-input>
                    <datalist id="dept-list-id">
                        <option v-for="(dept,index) in departments" v-bind:key="index">{{dept.name}}</option>
                    </datalist>
                </div>
            </b-col>
        </b-row>

        <!-- Patient Section -->
        <!-- <b-row class="d-print-none">
            <b-col cols="3" class="pr-0 d-print-none" v-if="!rentalForm.specimenId">
                <b-form-group label="Patient :" class="d-print-none">
                    <b-button variant="primary" class="mr-2" @click="openPatientsBrower">Select</b-button>
                    <b-button @click="newPatient" class="mr-2">New</b-button>
                    <b-button @click="editPatient" v-show="rentalForm.patient" variant="warning">Edit</b-button>
                </b-form-group>
            </b-col>

            <b-col cols="3">
                <b-form-group label="Type :">
                    <b-form-select v-model="rentalForm.type" :options="specimenType" :disabled="isDisabled" />
                </b-form-group>
            </b-col>

            <b-col cols="2" class="pr-0">
                <b-form-group label="Date collected:">
                    <b-form-input type="date" v-model="rentalForm.date" class="px-1" :disabled="isDisabled"></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <template v-if="rentalForm.patient">
                <b-col>
                    <div class="doc-form d-flex" style="min-width:300px;">
                        <b class="mr-2" style="min-width:70px;">Patient : </b>
                        <b-form-input :value="rentalForm.patient.title + ' ' + rentalForm.patient.name + ' ' +  rentalForm.patient.surname" readonly />
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="doc-form d-flex" style="min-width:90px;">
                        <b class="mr-2" style="min-width:20px;">HN</b>
                        <b-form-input :value="rentalForm.patient.hn" readonly />
                    </div>
                </b-col>
                <b-col cols="2">
                    <div class="doc-form d-flex" style="min-width:50px;">
                        <b class="mr-2" style="min-width:30px;">Age </b>
                        <b-form-input class="text-right px-2" :value="patientAge" readonly />
                        <span class="ml-1">years </span>
                    </div>
                </b-col>
            </template>
        </b-row> -->

        <!-- Patient Section -->

        <b-form-row class="my-2">
            <b-col cols="2" class="px-2">
                <b-form-group label="Date of take out :" :disabled="isDisabled">
                    <b-form-input type="date" v-model="rentalForm.dateTakeout" />
                </b-form-group>
            </b-col>
            <b-col cols="6" class="doc-form">
                <b-form-row class="mb-2">Reason : </b-form-row>
                <b-form-row class="form-inline">
                    <b-form-radio name="reason" value="refer" v-model="rentalForm.reason" :disabled="isDisabled">refer for further management </b-form-radio>
                    <b-input size="sm" v-if="rentalForm.reason=='refer'" v-model="rentalForm.reasonOther" />
                </b-form-row>
                <b-form-row>
                    <b-form-radio name="reason" value="consultation" v-model="rentalForm.reason" :disabled="isDisabled">consultation</b-form-radio>
                </b-form-row>
                <b-form-row class="form-inline">
                    <b-form-radio name="reason" value="other" v-model="rentalForm.reason" :disabled="isDisabled">other</b-form-radio>
                    <b-input size="sm" class="w-50" v-if="rentalForm.reason=='other'" v-model="rentalForm.reasonOther" />
                </b-form-row>
            </b-col>

            <b-col cols="2">
                <b-form-group label="Date of return :" v-if="isDisabled">
                    <b-form-input type="date" v-model="rentalForm.dateReturn" />
                </b-form-group>
            </b-col>

            <b-col cols="2">
                <b-form-group label="Return Note :" v-if="isDisabled">
                    <b-form-input v-model="rentalForm.returnNote" />
                </b-form-group>
            </b-col>
        </b-form-row>
        <!-- <pre>{{rentalForm.specimens}}</pre> -->

        <SpecimenRental v-for="(specimen,index) in rentalForm.specimens" :specimen="specimen" :key="index" :index="index" :disabled="isDisabled" class="border-top" />

        <b-button @click="add" :disabled="isDisabled">Add Manual</b-button>
        <b-button @click="showSpecimenSelector" :disabled="isDisabled" class="ml-2">Add From Records</b-button>

        <SpecimenSelector ref="specimenModal" />

    </b-container>
</template>

<script>
import { mapState } from 'vuex'

import SpecimenSelector from '../components/SpecimenSelector.vue'
import SpecimenRental from '../components/SpecimenRental.vue'

import axios from 'axios'

export default {
    components: {
        SpecimenRental,
        SpecimenSelector,
    },

    data() {
        return {
            alert: { message: '', countDown: 0 },
            needSave: false,
            rentalForm: {
                specimens: [],
                dateTakeout: '',
                reason: '',
                reasonOther: '',
            }
        }
    },

    computed: {            
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),

        doctors() {
            return this.users.filter(u => u.isActive != false && (u.role == 'doctor' || u.role == 'oncologist' || u.role == 'pathologist'))
        },

        doctor() {
            let doctor = this.doctors.find(d => d._id == this.rentalForm.doctor)
            if (!doctor) return { name: '', title: '' }

            let part = doctor.name.split('.')
            return { name: part[1], title: part[0] }
        },

        deptName() {
            return (this.departments.find(d => d._id == this.rentalForm.department) || { name: '' }).name
        },

        isDisabled() {
            return this.$route.name == 'SlideRentalReturn'
        }
    },

    watch: {
        rentalForm: {
            deep: true,
            handler() {
                this.needSave = true
            }
        }
    },

    mounted() {
        if (['SlideRentalInfo', 'SlideRentalReturn'].indexOf(this.$route.name) != -1) {
            axios(`/api/sliderentals/${this.$route.params.id}`)
                .then(res => {
                    this.rentalForm = res.data
                    this.$nextTick(() => { this.needSave = false })
                })
        }
        else {
            //this.add()
        }
    },

    methods: {
        selectDoctor(input) {
            let doctor = this.doctors.find(d => d.name == input) || {}
            this.rentalForm.doctor = doctor._id || null
            this.$set(this.rentalForm, 'department', doctor.department || null)
        },

        selectDepartment(input) {
            let dept = this.departments.find(d => input === d.name) || {}
            this.rentalForm.department = dept._id || null
        },

        selectPatient(patient) {
            this.rentalForm.patientId = patient._id
            this.rentalForm.patient = patient
        },

        openPatientsBrower() {
            this.$refs['patientsBrowerModal'].show()
        },

        
        showSpecimenSelector() {
            this.$refs.specimenModal
                .show()
                .then(specimen => {
                    this.rentalForm.specimens.push({
                        ref: specimen._id,
                        info: specimen,
                        number: specimen.number,
                        patient: specimen.patient._id,
                        slide: 0,
                        paraffin: 0,
                        slideNumbers: {},
                        paraffinNumbers: {},
                    })
                })
        },

        add() {
            this.rentalForm.specimens.push({ ref: null, number: '', slide: 1, slideNumbers: {}, paraffinNumbers: {} })
        },

        remove(index) {
            this.rentalForm.specimens.splice(index, 1)
        },

        save() {
            let method = (this.rentalForm._id) ? 'PUT' : 'POST'
            let btn = event.srcElement
            btn.disabled = true

            axios({
                method,
                url: `/api/sliderentals/${(this.rentalForm._id) ? this.rentalForm._id : ''}`,
                data: this.rentalForm,
            })
                .then(res => {
                    this.rentalForm = res.data
                    if (method == 'POST') this.$router.replace('/slide/' + res.data._id)

                    this.needSave = false
                    this.alert.message = 'Save done'
                })
                .catch(err => {
                    this.alert.message = err.response.data.message || err.message
                })
                .finally(a => {
                    this.alert.countDown = 5
                    btn.disabled = false
                })
        },
    }

}
</script>

<style scoped>
.doc-form input[type="text"] {
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.4rem;
    margin-left: 5px;
    margin-top: 5px;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>