<template>
    <div>
        <div class="form-inline mb-3 mt-2">
            <div>
                <h5 class="mt-1 mr-2">{{title}} </h5>
                <span class="d-none d-print-block"> 
                    <b>Month:</b> {{filter.year}} - {{filter.month.toString().padStart(2,'0')}} 
                    <b>Type:</b> {{filter.type}}
                </span>
            </div>
            <hr/>
            <div class="form-inline ml-auto d-print-none">
                <div class="form-inline" v-show="showDepartments">
                    <label><b>Department :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.department" :options="departments"></b-form-select>                      
                </div>
                <div class="form-inline" v-show="typeSelector">
                    <label><b>Type :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.type" :options="specimenType"/>
                </div>
                <div class="form-inline mx-4">
                    <label><b>Year :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.year" :options="Array.from(Array(5)).map((e,i)=>i+2019)" />
                </div>
                <div class="form-inline ">
                    <label><b>Month :  </b></label>
                    <b-form-select class="ml-2" size="sm" v-model="filter.month" :options="Array.from(Array(12)).map((e,i)=>i+1)" />
                </div>
                <b-button size="sm" class="ml-2" variant="success" @click="reloadData">Refresh</b-button>
            </div>
        </div>

        <Doughnut :height="100"  ref="pie" class="d-print-none mb-3" v-if="showChart"/>
        <Bar :height="100"  ref="chart" class="d-print-none" v-if="showChart"/>

        <b-table striped hover border  outlined :items="items" :fields="fields"  ref="table" class="border-top border-dark">
            <template v-slot:cell(number)="row">
                <u :class="{'text-decoration-none':!row.item.ess}">{{row.item.number}}</u>
                <i class="fas fa-star-of-life text-warning" v-if="row.item.important"></i>
            </template>
            <template v-slot:table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: (field.key === 'name' || field.key === 'confirmUser' )? '160px' : '90px'}" 
                >
            </template>
        </b-table>
    </div>
</template>

<script>


import { Line, Bar ,Doughnut,mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

import axios from 'axios'
import { mapState } from 'vuex'


export default {
    components: {
        Bar,
        Doughnut,
  	},
    data(){
        return {
            title   : '',
            items   : [],
            filter  : {year : 2000,month:1,type:'papsmear'},
            fields  : ['date'],
            typeSelector : true,
            showDepartments : false,
            specimenType    : [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
                { value: 'aoutlab', text: 'Outlab' },
                { value: 'frozen', text: 'Frozen section'},
                { value: 'liquidbase', text: 'Liquid-based cyto.(Gyne.)' },
            ],

            showChart : false,
            chartData: {
				labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30","31"],
				datasets : [
					{
						label: 'A',
						backgroundColor: 'green',
						data: [0]
                    },
				]
    		},
			chartOptions: {
				responsive: true,
				maintainAspectRatio: true,
				height : 300,
			}
        }
    },
    
    computed: {
        ...mapState("department",{
            departments: state => state.data.map(row => new Object({'text':row.name, 'value': row._id}) )            
        }),


    },

    mounted(){
        let now                 = new Date
        this.filter.month       = now.getMonth() + 1
        this.filter.year        = now.getFullYear()
        this.reloadData()
    },

    watch : {
        '$route.path':{
            handler() {
                this.$nextTick( event => {
                    this.reloadData()
                })                
            },
        }
    },

    methods : {
        async reloadData(){
            let path            = this.$route.path.split('/')
            this.typeSelector   = true 

            
            
            if(path[3] == 'subtype' && path[4] != 'byspecimen')     this.typeSelector   = false
            if(path[2] == 'papsmear' && path[3] == 'multi')         this.typeSelector   = false
            if(path[2] == 'material' && path[3] != 'all')           this.typeSelector   = false
            if(path[2] == 'papsmear' && path[3] == 'department')    this.typeSelector   = false

            this.showDepartments     = (path[2] == 'papsmear' && path[3] == 'department')            

            let query           = Object.keys(this.filter).map( q =>  q + '=' + this.filter[q] )
            
            
            const barColor      = ["#41B883", "#E46651", "#00D8FF",'yellow','blue','wheat','black','white']

            let res             = await axios.get('/api' + this.$route.path + '?' + query.join('&'))

            this.title          = res.data.title
            this.items          = res.data.items
            this.fields         = res.data.fields
            this.$refs.table.refresh()

            this.showChart      = Boolean(res.data.chart)
            if(!res.data.chart) return

            let datasets    = []
            let labels      = []
            let fieldName   = []                
            let preArr      = Array.from(new Array(31),(val,index)=>0)
            
            let summary     = {}


            

            //Pre Setup label
            this.fields.forEach((field,index) => {
                if(field.key == 'date') return
                fieldName.push(field.key || field)
                summary[field.key || field]  = 0

                let label       = field.label || field
                labels.push(label)
                datasets.push({
                    label: label,
                    backgroundColor: barColor[index-1],
                    data : [...preArr]
                })
            })

            //Dataset value
            this.items.forEach( item => {
                let day			= parseInt(item.date.substr(8,2) - 1)                    
                fieldName.forEach((field,index) => {
                    datasets[index].data[day]  = item[field] || 0
                    summary[field] +=   item[field] || 0
                })
            })

            //Pie Chart Summary Data
            let pieChartData        = {
                hoverBorderWidth: 10,
                labels: labels,
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: barColor,
                        data: Object.values(summary)
                    }
                ]
            }
        
            this.chartData.datasets    = datasets
            this.$refs.chart && this.$refs.chart.renderChart(this.chartData, this.options)                
            this.$refs.pie && this.$refs.pie.renderChart(pieChartData, {hoverBorderWidth: 20})            
        }
    }

}
</script>