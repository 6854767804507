<template>
    <b-container>
        <b-row class="my-4 px-2">            
            <b-col class="d-flex">
				<h3 class="my-auto">Slide Rental</h3>
				<b-button class="ml-3" variant="primary" @click="$router.push('slide/create')">Create</b-button>				
			</b-col>
            <b-col class="text-right">
				<b-form-group label-cols-sm="4" class="mb-0">
				<b-input-group>
					<b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
					<b-dropdown-item
						@click="search.by=$event.srcElement.text"
						v-for="option in search.options"
						:key="option"
					>{{option}}</b-dropdown-item>
					</b-dropdown>
					<b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
					<b-input-group-append>
					<b-button size="sm" @click="searchPatient">
						<i class="fas fa-search"></i>Search
					</b-button>
					</b-input-group-append>
				</b-input-group>
				</b-form-group>
			</b-col>
        </b-row>
        
        <b-table striped hover :items="rentals" :fields="fields">
            <template v-slot:cell(date)="row">
                {{new Date( parseInt( row.item._id.substr(0,8), 16 ) * 1000).toISOString().substr(0,10)}}
            </template>
            <template v-slot:cell(number)="row">            
                <p v-for="specimen in row.item.specimens" :key="specimen.ref">{{specimen.number.substr(0,3)}}-{{specimen.number.substr(3,4)}}</p>
            </template>
            <template v-slot:cell(hn)="row">            
                <p v-for="specimen in row.item.specimens" :key="specimen.ref">
                    <span v-if="specimen.patient">{{specimen.patient.hn}}</span>
                    <span v-if="!specimen.patient">{{specimen.patientHN}}</span>
                </p>                
            </template>

            <template v-slot:cell(patient)="row">            
                <div v-for="specimen in row.item.specimens" :key="specimen.ref">
                    <span v-if="specimen.patient">{{specimen.patient.title}} {{specimen.patient.name}} {{specimen.patient.surname}}</span>
                    <span v-if="!specimen.patient">{{specimen.patientName}}</span>
                </div>                
            </template>

            <template v-slot:cell(createBy)="row">
                {{usernameById(row.item.createBy)}}
            </template>

            <template v-slot:cell(_id)="row">
                <div class="d-flex">
                    <b-button variant="outline-primary" size="sm" @click="$router.push('slide/' + row.item._id)">Edit</b-button>
                    <b-button :variant="((row.item.dateReturn)?'':'outline-') + 'info'" class="ml-2" size="sm" @click="$router.push('slide/' + row.item._id + '/return')">Return</b-button>
                </div>
            </template>
        </b-table>
        <b-pagination
			v-model="paginate.page"
			:total-rows="paginate.counts"
			:per-page="paginate.pagesize"
			align="fill"
        />        
    </b-container>
</template>
<script>

import axios from "axios"
import { mapState } from 'vuex'


export default {
    data(){
        return {
            paginate : { counts : 0, page:1,pagesize: 30},
            rentals : [],
            search: {
				options: ["name", "hn"],
				keyword: "",
				by: "hn"
			},
            fields : ['dateTakeout','reason',
                {key: 'specimens.length', label:'Count'},                
                {key: 'number', label:'Specimen'},
                {key: 'hn', label:'HN'},
                {key: 'patient', label:'Patient'},

                {key: 'createBy', label: 'User'},
                {key:'_id', label: ''},
            ]
        }
    },

    computed : {
        ...mapState('user',{users: state => state.data}),
    },

    mounted(){
        axios.get(`/api/sliderentals/`).then(r => {
			this.rentals	= r.data
		})

        axios.get(`/api/sliderentals/count`).then(r => {
            this.paginate.counts	= r.data.counts
            this.paginate.pagesize	= r.data.pagesize
		})
    },

    watch : {
		'paginate.page' : function(page){
			axios
			.get(`/api/sliderentals/?page=${page}`)
			.then(r => this.rentals = r.data)			
		}
	},

    methods : {
        usernameById(id){
			const user    = this.users.find( u => u._id === id)
			return user && user.username
        },
        
        searchPatient() {
			let query 	= this.search.keyword.length? `?${this.search.by}=${this.search.keyword}`: "";
			
			axios.get(`/api/sliderentals/${query}`)
			.then(r => this.rentals = r.data)
		},
    }
}
</script>

<style scoped>
p {
    font-family: monospace;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
}
</style>