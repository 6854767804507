<template>
    <b-container>
        <div class="d-flex my-3">
            <h3 class="my-auto mr-3">Users</h3>
            <b-button @click="userFormModal">Create</b-button>
            <b-input-group class="ml-auto w-50">
                <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                <b-dropdown-item
                    @click="search.by=$event.srcElement.text"
                    v-for="option in search.options"
                    :key="option"
                >{{option}}</b-dropdown-item>
                </b-dropdown>
                <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
            </b-input-group>    
        </div>
        
        <b-table striped hover :items="usersFilter" :fields="fields">
            <template v-slot:cell(username)="row">
                <input type="checkbox" class="form-check-input" :checked="row.item.isActive != false" disabled>
                <a href="#" @click="userFormModal(row.item)">{{row.item.username}}</a>
            </template>
            <template v-slot:cell(department)="row">
                {{deptNameById[row.item.department]}}
            </template>
        </b-table>
        <b-modal ref="user-modal" title="User Info" @ok="save">
            <b-row>
                <b-col>
                    <b-form-group label="Name :">
                    <b-form-input v-model="userForm.name"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col >
                    <b-form-group label="Department :">
                        <b-form-input name="department" list="dept-list-id" @change="selectDepartment($event)" :value="deptName(userForm.department)" :disabled="!this.departments.length"></b-form-input>
                        <datalist id="dept-list-id">
                            <option v-for="(dept,index) in departments" v-bind:key="index">{{dept.name}}</option>
                        </datalist>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Username :">
                    <b-form-input v-model="userForm.username"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col class="px-0">
                    <b-form-group label="Password :">
                    <b-form-input type="password" v-model="userForm.password" autocomplete="off"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Role :">
                        <b-form-select v-model="userForm.role" :options="roles"></b-form-select>                        
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="chk-active" v-model="userForm.isActive">
                <label class="form-check-label" for="chk-active">Active</label>
            </div>
        </b-modal>
    </b-container>
</template>
<script>

import axios from "axios"
import { mapState } from 'vuex'

export default {
    data(){
        return {
            reports : [],
            fields : ['name','username','role','department'],
            formIndex : null,
            userForm : {username:'',name:'',role:'doctor',department : ''},
            roles : [
                {'text':'Pathologist',value:'pathologist'},
                {'text':'Cytotechnologist',value:'cytotechnologist'},
                {'text':'Medical sci',value:'scientist'},
                {'text':'Officer',value:'officer'},                
                {'text':'Doctor',value:'doctor'},
                {'text':'Other hospital',value:'other'},
                {'text':'Nurse',value:'nurse'},
                {'text':'Nurse gyne',value:'nurse_gyne'},
                {'text':'Medical student',value:'med_student'},
                {'text':'Oncologist',value:'oncologist'},
            ],
            deptNameById : {},
            usersFilter : [],
            search: {
                options: ["username", "name"],
                keyword: "",
                by: "name"
            },
        }
    },

    computed : {
        ...mapState('user',{users: state => state.data}),
        ...mapState("department",{departments: state => state.data}),
    },

    watch : {
        departments(){
            this.deptNameById   = {}
            this.departments.forEach( d => this.deptNameById[d._id] = d.name)            
        },

        users(){
            this.usersFilter    = this.users
        },

        'search.keyword': function(value){
            let by              = this.search.by
            this.usersFilter    = this.users.filter( u => u[by] && u[by].search(value) != -1 )
        }
    },

    mounted(){
        this.usersFilter    = this.users
        this.deptNameById   = {}
        this.departments.forEach( d => this.deptNameById[d._id] = d.name)
    },

    methods : {
        deptName(deptId){
            return (this.departments.find(d => d._id == deptId) || {name:''}).name
        },

        selectDepartment(input){
            let dept                    = this.departments.find( d => input === d.name) || {}
            this.userForm.department    = dept._id || null
        },
        
        userFormModal(user){
            event.preventDefault()
            this.userForm       = (user)? {...user} : {username:'',name:'',role:'doctor',department : null}
            this.formIndex      = this.users.indexOf(user)
            if(this.userForm.isActive != false){
                this.userForm.isActive   = true
            }
            this.$refs["user-modal"].show();
        },

        save(bvModalEvt){
            bvModalEvt.preventDefault();
            
            let method      = this.userForm._id ? "PUT" : "POST";
            let path        = this.userForm._id ? this.userForm._id : "";

            this.usersFilter    = []            

            this.$store
                .dispatch('user/save',this.userForm)
                .then( r => {
                    this.$refs["user-modal"].hide() 
                    this.$set(this,'usersFilter',this.users)
                })
        }
    }
}
</script>