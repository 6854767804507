import Vue from 'vue'
import Router from 'vue-router'

import Patients from './views/Patients.vue'

import Specimens from './views/Specimens.vue'
import SpecimenForm from './views/SpecimenForm.vue'

import Reports from './views/Reports.vue'
import ReportForm from './views/ReportForm.vue'
import Print from './views/Print.vue'

import SlideRentals from './views/SlideRentals.vue'
import SlideRentalForm from './views/SlideRentalForm.vue'

import Users from './views/Users.vue'
import Department from './views/Department.vue'


import Stats from './views/Stats.vue'

import StatSpecimen from './views/StatSpecimen.vue'
import StatDepartment from './views/StatDepartment.vue'

import RequisitionList from './views/RequisitionList.vue'
import RequisitionForm from './views/RequisitionForm.vue'

import ViewerReports from './views/ViewerReports.vue'
import ViewerPrint from './views/ViewerPrint.vue'

import RentalForm from './views/RentalForm.vue'

// import store from './store'
// function checkPermission(to,from,next){
//   let user    = store.state['session'].user || {}
//   if(user.role == 'other'){
//     if(to.name == 'ReportInfo')
//       return next('/print/' + to.params.id)
//     else
//     return next('/report')
//   }
//   next()
// }

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/stats',
      name: 'stats',
      component: Stats,      
      children: [
        {
          path: 'material/:type',
          component: StatSpecimen
        },
        {
          path: 'specimen/:topic',
          component: StatSpecimen
        },
        {
          path: 'report/confirm',
          component: StatSpecimen
        },
        {
          path: 'report/subtype/:type',
          component: StatSpecimen
        },
        {
          path: 'papsmear/:type',
          component: StatSpecimen
        },
        {
          path: 'papsmear/diagnosis/:type',
          component: StatSpecimen
        },
      ]
    },    
    
    {
      path: '/',
      name: 'RequisitionListRoot',
      component: RequisitionList
    },
    {
      path: '/patients',
      name: 'PatientList',
      component: Patients,
    },
    {
      path: '/specimen/',
      name: 'SpecimenList',
      component: Specimens,
    },
    {
      path: '/requisition',
      name: 'RequisitionList',
      component: RequisitionList,
    },
    {
      path: '/requisition/:id',
      name: 'RequisitionForm',
      component: RequisitionForm,
    },
    {
      path: '/specimen/:id',
      name: 'SpecimenInfo',
      component: SpecimenForm,
    },
    {
      path: '/specimen/new/:id',
      name: 'NewSpecimen',
      component: SpecimenForm,
    },
    {
      path: '/report/',
      name: 'ReportList',
      component: Reports
    },
    {
      path: '/report/:id',
      name: 'ReportInfo',
      component: ReportForm,
    },
    {
      path: '/report/specimen/:sid',
      name: 'NewReport',
      component: ReportForm,
    },
    {
      path: '/print/:id',
      name: 'Print',
      component: Print,
    },    
    {
      path: '/user',
      name: 'Users',
      component: Users,
    },
    {
      path: '/department',
      name: 'Department',
      component: Department,
    },
    {
      path: '/slide',
      name: 'SlideRentalList',
      component: SlideRentals,
    },
    {
      path: '/slide/create',
      name: 'SlideRentalCreate',
      component: SlideRentalForm,
    },
    {
      path: '/slide/:id',
      name: 'SlideRentalInfo',
      component: RentalForm,
    },
    {
      path: '/slide/:id/return',
      name: 'SlideRentalReturn',
      component: SlideRentalForm,
    },

    {
      path: '/viewer-report',
      name: 'ViewerReports',
      component: ViewerReports
    },    
    {
      path: '/viewer-report/:id',
      name: 'ViewerReportInfo',
      component: ViewerPrint
    },

    // {
    //   path: '/rental',
    //   name: 'RentalList',
    //   component: RentalList
    // },

    {
      path: '/rental/:id',
      name: 'RentalForm',
      component: RentalForm
    },
  ]
})