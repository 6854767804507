<template>
    <b-container>
        <div class="form-inline">
            <h3 class="my-4 d-inline mr-2">Report ({{report.status}})</h3>
            <template v-if="report._id && !needSave">
                <b-button variant="primary" @click="showAuthen" v-if="this.report.status != 'Confirm'" :disabled="!canConfirm">Confirm</b-button>
                <b-button variant="warning" @click="showAuthen" v-if="report.status=='Confirm'" :disabled="!canRevise">Revise</b-button>
                <b-button class="ml-2" @click="$router.push('/print/' + report._id) " v-if="canPrint">Print</b-button>
            </template>

            <div class="ml-auto form-inline text-left">
                <b-alert
                    fade
                    :show="alert.countDown"
                    @dismissed="alert.countDown=0"
                    variant="warning"                     
                    class="d-inline mb-0 py-1 mr-3" 
                >
                {{alert.message}}
                </b-alert>

                <b-form-checkbox v-model="report.important" class="my-auto mr-3">Mark as important</b-form-checkbox>
                <b-button variant="success" class="d-inline " @click="save" :disabled="report.status=='Confirm'">Save</b-button>
            </div>
        </div>

        <SpecimenInfo :specimen="specimen" :patient="patient" :disabled="true"/>
        
        <h5 class="px-2 mt-2" v-if="report.confirmed && report.confirmed.length">Confirm</h5>
        <b-card-group deck :columns="true" class="mb-5 px-2">    
            <b-card border-variant="success" style="max-width: 13rem;" v-for="(revise,index) in report.confirmed" :key="'rv_' + index">
                <b-card-text>
                    <label>{{new Date(revise.date).toLocaleString('en-GB')}}</label>
                    <label>By : {{usernameById(revise.user)}}</label>
                </b-card-text>
                <b-button @click="viewConfirm(index)">View</b-button>
            </b-card>
        </b-card-group>

        <!-- <pre style="white-space: pre-wrap;">{{report.current}}</pre> -->

        <b-row class="mb-3 border-top pt-3 mx-1">
            <b-col cols="11">
                <b-form-radio-group
                    class=""
                    name="subtype-options"
                    v-model="report.current.subtype"
                    :options="subtypes"
                    :disabled="report.status=='Confirm'"
                />
            </b-col>
            <b-col cols="1" v-if="['tissueorgan','cellblock'].indexOf(specimen.type) != -1">
                <b-form-checkbox v-model="report.current.ess">ESS</b-form-checkbox>
            </b-col>
        </b-row>

        <PapSmearForm :papsmear="report.current.papsmear" v-if="specimen.type=='papsmear'" :disabled="report.status=='Confirm'"/>
        <PapSmearForm :papsmear="report.current.liquidbase" v-if="specimen.type=='liquidbase'" :disabled="report.status=='Confirm'"/>

        <b-tabs content-class="mt-2" v-else>
            <b-tab title="Non-gyne cytology" v-if="specimen.type=='nongyne'">
                <b-form>
                    <b-form-row>
                        <b-col cols="2" class="text-right my-auto"><label class="mb-0">Site :</label></b-col>
                        <b-col><b-input v-model="report.current.site" :disabled="report.status=='Confirm'" ></b-input></b-col>
                    </b-form-row>
                    <b-form-row class="my-3">
                        <b-col cols="2" class="text-right my-auto"><label class="mb-0">Detail of specimen :</label></b-col>
                        <b-col><b-input v-model="report.current.detail" :disabled="report.status=='Confirm'" ></b-input></b-col>
                    </b-form-row>
                    <b-form-row class="my-3">
                        <b-col cols="2" class="text-right my-auto"><label class="mb-0">Diagnosis :</label></b-col>
                        <b-col><b-input v-model="report.current.diagnosis" :disabled="report.status=='Confirm'"></b-input></b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols="2" class="text-right"><label class="mb-0">Comment :</label></b-col>
                        <b-col>                            
                            <TextEditor :content="report.current" field='comment' :user="userId" :disabled="report.status=='Confirm'" ref="textedit1"/>
                            <h6>edit : {{usernameById(report.current.comment_user)}}</h6>
                        </b-col>                        
                    </b-form-row>                    
                    <b-form-row>
                        <b-col cols="2" class="text-right my-auto"><label class="mb-0">Screener :</label></b-col>
                        <b-col><b-input v-model="report.current.screener" :disabled="report.status=='Confirm'" ></b-input></b-col>
                    </b-form-row>                    
                </b-form>
            </b-tab>
            <b-tab title="Pathological Diagnosis" v-if="['cellblock','slidereview','tissueorgan','frozen'].indexOf(specimen.type)!=-1">
                <TextEditor :content="report.current" field='diagnosis' :user="userId" :disabled="report.status=='Confirm'" ref="textedit2"/>
                <h6>edit : {{usernameById(report.current.diagnosis_user)}}</h6>
            </b-tab>
            <b-tab title="Gross Examination" v-if="['cellblock','slidereview','tissueorgan','frozen'].indexOf(specimen.type)!=-1">
                <TextEditor :content="report.current" field='gross' :user="userId" :disabled="report.status=='Confirm'" ref="textedit3"/>
                <b-input v-model="report.current.editby" :disabled="report.status=='Confirm'" />
                <h6>edit : {{usernameById(report.current.gross_user)}}</h6>
            </b-tab>
            <b-tab title="OutLab" v-if="['aoutlab'].indexOf(specimen.type)!=-1">
                <TextEditor :content="report.current" field='outlab' :user="userId" :disabled="report.status=='Confirm'" ref="textedit4"/>
                <h6>edit : {{usernameById(report.current.outlab_user)}}</h6>
            </b-tab>
        </b-tabs>
        
        <hr v-if="report._id"/>            
        <h5 v-if="report._id">Files </h5>
        <b-card-group deck :columns="true" class="mb-5" v-if="report._id">    
            <b-card border-variant="success" :header="file.type" style="max-width: 12rem;" v-for="file in report.files" :key="file.filename">
                <b-card-text>{{file.original}}</b-card-text>
                <a class="btn btn-outline-secondary w-100" target="_blank" :href="'uploads/' + file.filename">Download</a>
                <a class="btn btn-outline-danger w-100 mt-1" @click="removeFile(file)">Remove</a>
            </b-card>
            <b-card border-variant="secondary" header="Upload" style="max-width: 20rem;">
                <b-form-file
                    v-model="file"
                    :state="Boolean(file)"
                    placeholder="Choose or drop"
                    drop-placeholder="Drop file here"
                />
                <b-button class="mt-2" @click="submitFile">Upload</b-button>
            </b-card>
        </b-card-group>

        <hr v-if="report._id"/>            
        <h5>Notes </h5>
        <b-textarea v-model="report.note" class="mb-2"/>
        <b-button variant="outline-danger" class="mb-5" @click="confirmDelete" v-if="canRevise" :disabled="this.report.status == 'Confirm'">Delete this report</b-button>
    
        <b-modal ref="authen-modal" id="confirm-modal" title="Confirm Report" ok-variant="warning" ok-title="Authenicate" @ok="preAuthen">
            <form @submit.stop.prevent="preAuthen">
                <b-form-group label="Password :" :description="authenState">
                    <b-form-input type="password" placeholder="Enter your password" v-model="confirmPass"/>
                </b-form-group>
            </form>
        </b-modal>

        <AuthenModal ref="authen"/>

    </b-container>    
</template>

<script>

import { mapState } from 'vuex'
import AuthenModal from '../components/AuthenModal.vue'
import TextEditor from '../components/TextEditor.vue'
import PapSmearForm from '../components/PapSmearForm.vue'
import SpecimenInfo from '../components/SpecimenInfo.vue'

import axios from 'axios'


const confirmRight  = {
    'pathologist'   : true,    
}

export default {
    components : {
        PapSmearForm,SpecimenInfo,TextEditor,AuthenModal
    },
    data(){
        return {
            file : null,
            report : {                
                specimen    : '',                
                current     : {
                    subtype : '',
                    papsmear: {type:[]},
                    liquidbase : {type:[]},
                },
            },
            alert       : {message : '',countDown :0},
            confirmPass : '',
            authenState : '',
            specimen    : { number:'', type:''},
            patient     : {},
            needSave    : false,
            subtypes: [
                { text: 'Unremarkable', value: 'Unremarkable' },
                { text: 'Reactive/inflammation', value: 'Reactive/inflammation' },
                { text: 'Benign tumor', value: 'Benign tumor' },
                { text: 'Borderline tumor', value: 'Borderline tumor' },
                { text: 'Malignancy', value: 'Malignancy' }
            ],
            specimenType    : [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
            ],

        }
    },

    computed : {
        ...mapState('user',{users: state => state.data}),
        ...mapState("session",['user']),

        userId(){
            return this.$store.state['session'].user.userId
        },

        canConfirm(){            
            if(this.user.role == 'pathologist')
                return true
            
            if(this.user.role == 'cytotechnologist' && this.specimen.type  == 'papsmear')
                return true
                        
            if(this.user.role == 'officer' && this.specimen.type  == 'aoutlab')
                return true

            return false
        },

        canPrint(){
            return ['scientist'].indexOf(this.user.role) == -1
        },

        canRevise(){
            if(!this.report._id) return false

            if(this.user.role == 'pathologist')
                return true                       
            return false
        }
    },

    watch : {
        'report.specimen' : function(a,b){                     
            if(this.specimen._id)   return

            let thisView            = this
            axios
                .get(`/api/specimens/${this.report.specimen}`)
                .then(r => {            
                    thisView.specimen           = r.data
                    thisView.report.patient     = r.data.patient
                })
        },

        papsmearForm(newValue){
            this.report.current.papsmear    =  newValue
        }
    },

    async mounted(){
        let thisView            = this

        if(this.$route.name == "NewReport"){
            this.report.specimen    = this.$route.params.sid
        }

        if(this.$route.name == "ReportInfo"){
            axios
            .get(`/api/reports/${this.$route.params.id}`)
            .then( r => {
                thisView.report             = r.data
            })
        }
    },

    methods : {
        resetForm(){            
            this.$set(this.report,'current', {
                detail: '',
                site: '',
                gross: '',
                diagnosis: '',
            }) 

            this.$nextTick( r => {
                this.$refs.textedit1 && this.$refs.textedit1.updateText()
                this.$refs.textedit2 && this.$refs.textedit2.updateText()
                this.$refs.textedit3 && this.$refs.textedit3.updateText()
                this.$refs.textedit4 &&  this.$refs.textedit4.updateText()
            }) 
        },
        
        confirmDelete(){
            this.$refs['authen'].show().then( res => {                
                axios.delete(`/api/reports/${this.report._id}`)            
                .then(data => {                
                    this.$router.replace(`/report`)
                })
                .catch( err => {
                    this.alert.message      = err.response.data.message || err.message
                })
            })
        },

        printReport(){
            window.open('/exportpdf/' + this.report._id) 
        },

        usernameById(id){
            return (this.users.find( u => u._id === id) || {username:''} ).username
        },

        viewConfirm(index){            
            this.report.current     = JSON.parse(JSON.stringify(this.report.confirmed[index].data))
            this.$nextTick( r => {
                this.$refs.textedit1 && this.$refs.textedit1.updateText()
                this.$refs.textedit2 && this.$refs.textedit2.updateText()
                this.$refs.textedit3 && this.$refs.textedit3.updateText()
                this.$refs.textedit4 &&  this.$refs.textedit4.updateText()
            })            
        },

        showAuthen(){
            //Prevent Confirm without 4 check
            if((this.specimen.type == 'papsmear' || this.specimen.type == 'liquidbase') && this.report.status != 'Confirm'){                
                let data    = (this.specimen.type == 'papsmear')? this.report.current.papsmear : this.report.current.liquidbase

                if(!data.type.length)   return alert('Select type !!')
                if(!(data.history && data.history.length))   return alert('Select history !!')

                if(!data.adequacy)  return alert('Select Specimen adequacy !!')
                //Allow no select General categorization when select Unsatisfactory for evaluation
                if(!data.categorization && !data.unsatisfactory)  return alert('Select Specimen categorization !!')
            }
    
            this.authenState    = ''
            this.confirmPass    = ''
            this.$root.$emit('bv::show::modal', 'confirm-modal')
        },
        
        preAuthen(bvModalEvt){
            bvModalEvt.preventDefault()
            this.authenState    = ''
            fetch(`/api/session/authen`,{
                method      : 'POST',
                headers     : {'Content-Type': 'application/json'},
                body        : JSON.stringify({password:this.confirmPass}),
                credentials : 'include',
            })
            .then(res => res.json())            
            .then(json => {
                if(json.code){
                    return this.authenState    = 'Authen Fail'                    
                }

                this.$refs['authen-modal'].hide()
                if(this.report.status == 'Confirm'){
                    this.report.status  = 'Revise'                
                    this.resetForm()
                }
                else
                    this.confirmReport()                
            })

        },

        confirmReport(){
            
            fetch(`/api/reports/${this.report._id}/confirm`,{
                method      : 'POST',
                headers     : {'Content-Type': 'application/json'},
                body        : JSON.stringify({password:this.confirmPass}),
                credentials : 'include',
            })
            .catch( err => {
                console.log('invalid authen')
            })
            .then(res => res.json())            
            .then(json => {
                console.log('do json')
                if(json.code)   return
                this.report             = json
                const current           = this.report.confirmed[this.report.confirmed.length - 1].data
                this.report.current     = JSON.parse(JSON.stringify(current))
            })
            
        },


        save(){
            let method      = (this.report._id)? 'PUT' : 'POST'            
            let btn         = event.srcElement
            btn.disabled    = true

            axios({
                method,
                url : `/api/reports/${(this.report._id)? this.report._id : ''}`,
                data  : this.report,
            })
            .then(res => {                
                this.report     = res.data
                if(method == 'POST')    this.$router.replace('/report/' + res.data._id)
                
                this.needSave   = false                                
                this.alert.message      = 'Save done'
            })
            .catch( err => {
                this.alert.message      = err.response.data.message || err.message
            })
            .finally( a => {
                this.alert.countDown    = 5
                btn.disabled            = false
            })           
        },
        

        submitFile(btnEvent){            
            let formData  = new FormData()        
            formData.append('uploadfield', this.file);

            btnEvent.target.disabled    = true        
            axios.post(`/api/reports/${this.report._id}/upload?filename=${this.file.name}`,formData,
            {
                headers: {'Content-Type': 'multipart/form-data'},
            })
            .then( res => {
                this.report.files.push(res.data)
                this.file = null
            })
            .finally( () => {
                btnEvent.target.disabled    = false
            })

        },

        removeFile(file){
            if(!confirm("Delete this upload file ?")) return

            let index       = this.report.files.indexOf(file)
            let filename    = file.filename.split('_')[1]

            axios.delete(`/api/reports/${this.report._id}/upload/${filename}`)
            .then( res => {
                this.report.files.splice(index,1)
            })          
        }
    }
}
</script>