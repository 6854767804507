<template>
  <div>
    <div class="mx-4 doc-form">
        <b-row >
            <div class="d-flex mb-3" >
                <label style="min-width:120px;" class="my-auto"><b>Estimate Date : </b></label>
                <b-input v-model="lab.estimateDate" type="date"/>
            </div>
        </b-row>
        <b-row>
            <b-form-radio name="labType" value="consultation" v-model="lab.type"><b>Consultation</b></b-form-radio>
        </b-row>
        <div v-if="lab.type == 'consultation'">
            <b-form-row>
                <b-form-checkbox-group class="form-inline col">
                    <b-form-checkbox  class="my-auto" >Slide </b-form-checkbox>
                    <b-input size="sm" v-model.number="lab.consultation.slide"></b-input>
                </b-form-checkbox-group >
                <b-form-checkbox-group class="form-inline col">
                    <b-form-checkbox  class="my-auto" >Total slide </b-form-checkbox>
                    <b-input size="sm" v-model.number="lab.consultation.totalSlide"></b-input>
                </b-form-checkbox-group >
            </b-form-row>
            <b-form-row class="mt-3">
                <b-form-checkbox-group class="form-inline col">
                    <b-form-checkbox  class="my-auto" >Paraffin block </b-form-checkbox>
                    <b-input size="sm" v-model.number="lab.consultation.paraffin"></b-input>
                </b-form-checkbox-group >
                <b-form-checkbox-group class="form-inline col">
                    <b-form-checkbox  class="my-auto" >Total paraffin block </b-form-checkbox>
                    <b-input size="sm" v-model.number="lab.consultation.totalParaffin"></b-input>
                </b-form-checkbox-group >
            </b-form-row>
        </div>

        <b-row class="mt-4">
            <b-form-radio name="labType" value="specimen" v-model="lab.type"><b>Tissue/organ specimen</b></b-form-radio>
        </b-row>
        <b-form-row v-if="lab.type == 'specimen'">
            <b-form-checkbox-group class="form-inline col">
                <b-form-checkbox  class="my-auto" >Container </b-form-checkbox>
                <b-input size="sm" v-model.number="lab.specimen.containers"></b-input>
            </b-form-checkbox-group >
        </b-form-row>


        <b-row class="mt-4">
            <b-form-radio name="labType" value="immuhc" v-model="lab.type"><b>Immunohistochemistry</b></b-form-radio>
        </b-row>
        <b-form-row v-if="lab.type == 'immuhc'">
            <b-form-checkbox-group class="col">
                <b-input size="sm" class="w-75" v-model="lab.immuhc"></b-input>
            </b-form-checkbox-group >
        </b-form-row>


        <b-row class="mt-4">
            <b-form-radio name="labType" value="genetic" v-model="lab.type"><b>Molecular genetic</b></b-form-radio>
        </b-row>
        <div class="pl-4" v-if="lab.type == 'genetic'">
            <b-form-row>
                <b-form-checkbox-group class="w-100 " name="genetic" v-model="lab.genetic">
                    <b-form-checkbox  class="my-auto col-3" value="FISH for HER2">FISH for HER2</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="FISH for ALK">FISH for ALK</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="EGFR mutation">EGFR mutation</b-form-checkbox>
                </b-form-checkbox-group >
            </b-form-row>
            <b-form-row class="mt-3">
                <b-form-checkbox-group class="w-100 " name="genetic" v-model="lab.genetic">
                    <b-form-checkbox  class="my-auto col-3" value="KRAS mutation">KRAS mutation</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="PCR for TB">PCR for TB</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-1" >Other</b-form-checkbox>
                    <b-input size="sm" class="col-3 d-inline" v-model="lab.genetic_other"></b-input>                
                </b-form-checkbox-group >
            </b-form-row>
        </div>

        <b-row class="mt-4">
            <b-form-radio name="labType" value="stain" v-model="lab.type"><b>Special stain</b></b-form-radio>
        </b-row>
        <div class="pl-4" v-if="lab.type == 'stain'">
            <b-form-row>
                <b-form-checkbox-group class="w-100 " name="stain" v-model="lab.stain">
                    <b-form-checkbox  class="my-auto col-3" value="Mucin">Mucin</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="Fite stain">Fite stain</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="Congo red">Congo red</b-form-checkbox>
                </b-form-checkbox-group >
            </b-form-row>
            <b-form-row class="mt-3">
                <b-form-checkbox-group class="w-100 " name="stain" v-model="lab.stain">
                    <b-form-checkbox  class="my-auto col-3" value="Reticulin">Reticulin</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-3" value="Giemsa">Giemsa</b-form-checkbox>
                    <b-form-checkbox  class="my-auto col-1" >Other</b-form-checkbox>
                    <b-input size="sm" class="col-3 d-inline" v-model="lab.stain_other"></b-input>                
                </b-form-checkbox-group >
            </b-form-row>            
        </div>
        <b-row class="mt-4">
            <b-form-radio name="labType" value="other" v-model="lab.type"><b>Other</b></b-form-radio>
            <b-input size="sm" class="w-50 ml-3" v-model="lab.other" v-if="lab.type=='other'"></b-input>
        </b-row>
    </div>
  </div>
</template>

<script>

export default {
    props : ['lab'],
    watch : {
        'lab.type' : function(){
            let lab    = this.lab
            Object.keys(lab).forEach(key => {
                if(key == 'type')   return                
                lab[key]   = new lab[key].__proto__.constructor()
            })
            console.log('type change')
        }
    },

    mounted(){
        if(!this.lab.consultation)  this.lab.consultation   = {}
        if(!this.lab.specimen)      this.lab.specimen   = {}
    }
}
</script>
<style scoped>

.doc-form input[type="text"]{
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
} 


/* 
.custom-control{
    margin-bottom: 1rem;
}

.doc-form > div > div[role='group']{
    margin-left: 1.5rem;
}

.doc-form input[type="text"]{
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
} 
*/

</style>