<template>
  	<b-container class="mt-2">
		<b-row>
			<b-col cols="12">
				<div class="d-print-none">
					<div class="d-flex">
						<b-button size="sm" class="ml-auto"  v-b-toggle.sidebar-1>Stat Menu</b-button>
					</div>
					
					<b-sidebar id="sidebar-1" title="Menu" shadow >
						<b-nav class="p-3" vertical>
							<h6>จำนวนการใช้วัสดุ</h6>
							<b-nav-item to="/stats/material/all">All type</b-nav-item>
							<b-nav-item to="/stats/material/paraffin">Paraffin</b-nav-item>
							<b-nav-item to="/stats/material/slide">Slide</b-nav-item>
							
							<h6>จำนวนสิ่งส่งตรวจ</h6>
							<b-nav-item to="/stats/specimen/all">All Type</b-nav-item>
							<b-nav-item to="/stats/specimen/byspecimentype">Sub Type</b-nav-item>
							<b-nav-item to="/stats/specimen/stain">การย้อม Stain</b-nav-item>

							<h6>จำนวนผู้ป่วย</h6>
							<b-nav-item to="/stats/specimen/department">แยกตาม Deparmart</b-nav-item>

							<h6>การรายงานผล</h6>
							<b-nav-item to="/stats/report/confirm">ตามประเภทของ Specimen</b-nav-item>
							
							<h6>ผู้ป่วยในแต่ละกลุ่มโรค</h6>
							<b-nav-item to="/stats/report/subtype/byspecimen">ตามประเภทของ Specimen</b-nav-item>
							<b-nav-item to="/stats/report/subtype/Benign tumor">Benign tumor</b-nav-item>
							<b-nav-item to="/stats/report/subtype/Borderline tumor">Borderline tumor</b-nav-item>
							<b-nav-item to="/stats/report/subtype/Malignancy">Malignancy</b-nav-item>
							<b-nav-item to="/stats/report/subtype/Reactive-inflammation">Reactive/inflammation</b-nav-item>
							<b-nav-item to="/stats/report/subtype/Unremarkable">Unremarkable</b-nav-item>

							<h6>Pap Smear</h6>
							<b-nav-item to="/stats/papsmear/multi">มีผลการตรวจทั้งสอง</b-nav-item>
							<b-nav-item to="/stats/papsmear/department">ตาม Department</b-nav-item>
							<b-nav-item to="/stats/papsmear/diagnosis/negative">ผล Negative</b-nav-item>
							<b-nav-item to="/stats/papsmear/diagnosis/epithelial">ผล Epithelial</b-nav-item>
							<b-nav-item to="/stats/papsmear/epithelial">Negative และ Epithelial</b-nav-item>
						</b-nav>
					</b-sidebar>
				</div>
				<router-view></router-view>
			</b-col>
		</b-row>
  	</b-container>      
</template>

<script>

import axios from 'axios'

export default {
    data () {
      	return {
      	}
    },
    mounted () {
		let labels		= []
		let values		= []
    },
  }
</script>

<style>

h4,h5,h6 {
	font-family: 'Kanit', sans-serif;
}

li {
	font-family: 'Kanit', sans-serif;		
}

.small {
	max-width: 600px;
	margin:  150px auto;
}
</style>
