<template>
  <div>
    <div class="mx-4 doc-form">
        <b-row>
            <p class="my-auto"><b>Type of specimen</b></p>
            <!-- 
                <b-form-radio-group class="d-flex ml-4 mt-1" v-model="papsmear.type" :disabled="disabled">
                <b-form-radio value="Cervix">Cervix</b-form-radio>
                <b-form-radio value="Endocervix">Endocervix</b-form-radio>
                <b-form-radio value="Vagina">Vagina</b-form-radio>
                <b-form-radio value="Other">Other</b-form-radio>
                <b-input size="sm" v-model="papsmear.type_other"/>
                </b-form-radio-group >         
            -->

            
            <b-form-checkbox-group class="d-flex" v-model="papsmear.type" :disabled="disabled">
                <b-form-checkbox value="Cervix" class="my-auto">Cervix</b-form-checkbox>
                <b-form-checkbox value="Endocervix" class="my-auto mx-4">Endocervix</b-form-checkbox>
                <b-form-checkbox value="Vagina" class="my-auto mx-4">Vagina</b-form-checkbox>
                <b-form-checkbox value="Other" class="my-auto">Other</b-form-checkbox>
                <b-input size="sm" v-model="papsmear.type_other"/>
            </b-form-checkbox-group> 
           
        </b-row>
        <b-row >
            <p class="my-auto"><b>Patient history</b></p>
            <b-form-checkbox-group class="d-flex" style="margin-left:2.8rem;" v-model="papsmear.history" :disabled="disabled">
                <b-form-checkbox value="Check up" class="my-auto">Check up</b-form-checkbox>
                <b-form-checkbox value="Post-partum" class="my-auto mx-4">Post-partum</b-form-checkbox>
                <b-form-checkbox value="Post-abortion" class="my-auto mx-4">Post-abortion</b-form-checkbox>
            </b-form-checkbox-group>
        </b-row>
        <b-row  style="margin-left:7.1rem;">
            <b-form-checkbox-group class="d-flex" v-model="papsmear.history" :disabled="disabled">
                <b-form-checkbox value="AUB" class="my-auto">AUB</b-form-checkbox>
                <b-form-checkbox value="Other" class="my-auto">Other</b-form-checkbox>
                <b-input size="sm" v-model="papsmear.history_other"/>
            </b-form-checkbox-group>            
        </b-row>
        <b-row>
            <span class="w-100 mt-3"><b>The 2014 Bethesda system for reporting cervical cytology</b></span>
            <span><b>Specimen adequacy</b></span>
        </b-row>
        <b-form-row>
            <b-form-radio name="adequacy" value="satisfactory" v-model="papsmear.adequacy" :disabled="disabled">Satisfactory</b-form-radio>
        </b-form-row>
        <div  v-if="papsmear.adequacy == 'satisfactory'">
            <b-form-checkbox-group v-model="papsmear.satisfactory" :disabled="disabled" stacked>
                <b-form-checkbox value="SPET">Presence of endocervical/ transformation zone component</b-form-checkbox>
                <b-form-checkbox value="SAET">Absence of endocervical/ transformation zone component</b-form-checkbox>
            </b-form-checkbox-group >        
            <b-form-checkbox-group v-model="papsmear.satisfactory" :disabled="disabled">
                <b-form-checkbox value="SSTT">Smear too thickly spread</b-form-checkbox>
                <b-form-checkbox value="SSCL">Scant cellularity</b-form-checkbox>
                <b-form-checkbox value="SPOB">Partially obscuring blood/inflammation</b-form-checkbox>
            </b-form-checkbox-group>
        </div>
        <b-form-row>
            <b-form-radio name="adequacy" value="unsatisfactory" v-model="papsmear.adequacy" :disabled="disabled">Unsatisfactory for evaluation</b-form-radio>
        </b-form-row>
        <div  v-if="papsmear.adequacy == 'unsatisfactory'">
            <b-form-checkbox-group stacked>
                <b-form-radio name="unsatisfactory" value="reject"      v-model="papsmear.unsatisfactory" :disabled="disabled" >Specimen reject/ not processed (see comment)</b-form-radio>
                <b-form-radio name="unsatisfactory" value="processed"   v-model="papsmear.unsatisfactory" :disabled="disabled">Specimen processed and examined (see comment)</b-form-radio>
            </b-form-checkbox-group >
        </div>

        <b-row class="mt-3"><span><b>General categorization</b></span></b-row>
        <b-form-row>
            <b-form-radio 
                name="categorization" 
                value="negative"
                v-model="papsmear.categorization"
                :class="{'font-weight-bold':papsmear.categorization == 'negative'}"
                :disabled="disabled"
            >Negative for intraepithelial lesion or malignancy</b-form-radio>
        </b-form-row>
        <div v-if="papsmear.categorization == 'negative'">
            <b-form-checkbox-group class="form-inline ">                
                <b-form-radio name="negative" v-model="papsmear.negativeType" value="organism" class="my-auto mr-2" :disabled="disabled" >Organism : </b-form-radio>
                <b-input size="sm" v-model="papsmear.negative_organism" v-if="papsmear.negativeType=='organism'" :disabled="disabled"/>
            </b-form-checkbox-group >
            <b-form-checkbox-group stacked >
                <b-form-radio name="negative" v-model="papsmear.negativeType" value="non-neoplastic-finding" :disabled="disabled">
                    Non-neoplastic findings
                </b-form-radio>
            </b-form-checkbox-group >
            <div v-if="papsmear.negativeType=='non-neoplastic-finding'">
                <div style="margin-left:4rem;" class="form-inline">
                    <b-form-radio name="non_neoplastic" value="non-neoplastic" v-model="papsmear.negative_non_neotype" :disabled="disabled">
                        Non-neoplastic
                    </b-form-radio>
                    <b-form-checkbox-group  class="form-inline ml-5" v-if="papsmear.negative_non_neotype=='non-neoplastic'" v-model="papsmear.negative_non_neo_sub" :disabled="disabled">
                        <b-form-checkbox value="NSMP">Squamous metaplasia</b-form-checkbox>
                        <b-form-checkbox value="NKTC">Keratotic changes</b-form-checkbox>
                    </b-form-checkbox-group>
                    <b-form-checkbox-group style="margin-left:11.30rem;" v-if="papsmear.negative_non_neotype=='non-neoplastic'" v-model="papsmear.negative_non_neo_sub" :disabled="disabled">
                        <b-form-checkbox value="stp">Tubal metaplasia</b-form-checkbox>
                        <b-form-checkbox value="natp">Atrophy</b-form-checkbox>
                        <b-form-checkbox value="pob">Pregnancy-associated changes</b-form-checkbox>
                    </b-form-checkbox-group>
                </div>
                <div style="margin-left:4rem;" class="form-inline ">
                    <b-form-radio name="non_neoplastic"  value="reactive_cellular" class="mr-4" v-model="papsmear.negative_non_neotype" :disabled="disabled">
                        Reactive cellular changes associated with :
                    </b-form-radio>
                    <b-form-radio-group class="form-inline" v-if="papsmear.negative_non_neotype=='reactive_cellular'" v-model="papsmear.negative_non_neo_reactive" :disabled="disabled">
                        <b-form-radio name="reactive_cellular" value="NNIF" class="mr-4">Inflammation</b-form-radio>
                        <b-form-radio name="reactive_cellular" value="NNRT" class="mr-4">Radiation</b-form-radio>
                        <b-form-radio name="reactive_cellular" value="IUD">IUD</b-form-radio>
                    </b-form-radio-group>
                </div>
                <b-form-checkbox-group style="margin-left:4rem;" >
                    <b-form-radio name="non_neoplastic"  value="NGPH" class="mr-4" v-model="papsmear.negative_non_neotype" :disabled="disabled">
                        Glandular cells status post hysterectomy
                    </b-form-radio>                
                </b-form-checkbox-group>
            </div>
            <b-form-checkbox-group >
                <b-form-radio name="negative" v-model="papsmear.negativeType" value="NEMC" :disabled="disabled">
                    Endometrial cells in women ≥ 45 years old
                </b-form-radio>
            </b-form-checkbox-group>
        </div>

        <b-form-row>
            <b-form-radio 
                name="categorization" 
                value="epithelial"                
                v-model="papsmear.categorization"
                :class="{'font-weight-bold':papsmear.categorization == 'epithelial'}"
                :disabled="disabled"
            >
                Epithelial cells abnormalities
            </b-form-radio>
        </b-form-row>
        <div v-if="papsmear.categorization == 'epithelial'" class="ml-4">
            <b-form-checkbox-group>
                <b-form-radio name="epithelial" value="squamous" v-model="papsmear.epithelialType" :disabled="disabled">
                    Squamous cell
                </b-form-radio>
            </b-form-checkbox-group >
            <div style="margin-left:5rem;" v-if="papsmear.epithelialType=='squamous'" class="mt-1" >
                <div class="d-flex">
                    <b-form-radio name="squamous"    value="atypical" v-model="papsmear.epithelial_squamous" :disabled="disabled">
                        Atypical squamous cells :
                    </b-form-radio>
                    <b-form-radio-group class="ml-4" v-if="papsmear.epithelial_squamous=='atypical'" v-model="papsmear.epithelial_squamous_atypical" :disabled="disabled" stacked>
                        <b-form-radio value="ASCUS">of undetermined significance (ASC-US)</b-form-radio>
                        <b-form-radio value="ASCH">cannot excluded HSIL (ASC-H)</b-form-radio>
                    </b-form-radio-group>
                </div>
                <div class="form-inline">
                    <b-form-radio name="squamous"  value="LSIL" v-model="papsmear.epithelial_squamous" :disabled="disabled">
                        Low grade squamous intraepithelial lesion (LSIL) :
                    </b-form-radio>
                    <b-form-radio-group class="ml-4" v-if="papsmear.epithelial_squamous=='LSIL'" v-model="papsmear.epithelial_squamous_LSIL" :disabled="disabled">
                        <b-form-radio value="HPV">HPV change</b-form-radio>
                        <b-form-radio value="CIN1">CIN 1</b-form-radio>
                    </b-form-radio-group>
                </div>
                <div class="form-inline">
                    <b-form-radio name="squamous"  value="HSIL" v-model="papsmear.epithelial_squamous" :disabled="disabled">
                        High grade squamous intraepithelial lesion (HSIL) :
                    </b-form-radio>
                    <b-form-radio-group class="ml-4" v-if="papsmear.epithelial_squamous=='HSIL'" v-model="papsmear.epithelial_squamous_HSIL" :disabled="disabled">
                        <b-form-radio value="CIN2">CIN 2</b-form-radio>
                        <b-form-radio value="CIN3">CIN 3</b-form-radio>
                    </b-form-radio-group>
                </div>
                <b-form-radio name="squamous"  value="carcinoma" v-model="papsmear.epithelial_squamous" :disabled="disabled">
                    Squamous cell carcinoma
                </b-form-radio>                
            </div>

            <b-form-checkbox-group >
                <b-form-radio name="epithelial" value="glandular" v-model="papsmear.epithelialType" :disabled="disabled">
                    Glandular cell
                </b-form-radio>
            </b-form-checkbox-group >
            <div style="margin-left:5rem;" v-if="papsmear.epithelialType=='glandular'">
                <div class="d-flex">
                    <b-form-radio name="glandular"    value="atypical" v-model="papsmear.epithelial_glandular" :disabled="disabled">
                        Atypical :
                    </b-form-radio>
                    <b-form-radio-group class="ml-4" v-if="papsmear.epithelial_glandular == 'atypical'" name="glandular_atypical" v-model="papsmear.epithelial_glandular_atypical" :disabled="disabled">
                        <b-form-radio value="AGCC">endocervical cells, NOS</b-form-radio>
                        <b-form-radio value="AGCM">endometrial cells, NOS</b-form-radio>
                        <b-form-radio value="AGCO">glandular cells, NOS</b-form-radio>
                    </b-form-radio-group>                    
                </div>
                <b-form-radio-group style="margin-left:7.2rem;" v-if="papsmear.epithelial_glandular == 'atypical'" name="glandular_atypical"  v-model="papsmear.epithelial_glandular_atypical" :disabled="disabled">
                        <b-form-radio value="AECN">endocervical cells, favor neoplastic</b-form-radio>
                        <b-form-radio value="AGCN">glandular cells, favor neoplastic</b-form-radio>
                </b-form-radio-group>         

                <b-form-radio name="glandular"    value="endocervical" v-model="papsmear.epithelial_glandular" :disabled="disabled">
                    Endocervical adenocarcinoma in situ
                </b-form-radio>
                <div class="form-inline">
                    <b-form-radio name="glandular"    value="adenocarcinoma" v-model="papsmear.epithelial_glandular" :disabled="disabled">
                        Adenocarcinoma :
                    </b-form-radio>
                    <b-form-radio-group class="ml-4" v-if="papsmear.epithelial_glandular == 'adenocarcinoma'" name="adenocarcinoma" v-model="papsmear.epithelial_glandular_adenocarcinoma" :disabled="disabled">
                        <b-form-radio value="ECCA">Endocervical</b-form-radio>
                        <b-form-radio value="EMCA">Endometrial</b-form-radio>
                        <b-form-radio value="EUCA">Extrauterine</b-form-radio>
                        <b-form-radio value="CANO">NOS</b-form-radio>
                    </b-form-radio-group>                    
                </div>
            </div>
            <b-form-checkbox-group class="form-inline ">            
                <b-form-radio name="epithelial" class="my-auto mr-3" value="other" v-model="papsmear.epithelialType" :disabled="disabled">
                    Other malignant neoplasms
                </b-form-radio>        
                <b-input size="sm" class="w-50" v-if="papsmear.epithelialType =='other'" v-model="papsmear.epithelial_other" :disabled="disabled"/>
            </b-form-checkbox-group >            
        </div>        
        <b-row class="mt-4" v-if="(papsmear.comment && papsmear.comment.length) || !print">
            <p><b>Comment</b></p>
        </b-row>
        <b-row>
            <b-textarea class="w-100" rows="8" v-model="papsmear.comment" v-if="!print" :disabled="disabled"/>            
            <pre v-else>{{papsmear.comment}}</pre>
        </b-row>
    </div>
  </div>
</template>

<script>

function resetKey(target,startWith){    
    if(!target.needClear)   return

    let pap     = target.papsmear    
    Object
        .keys(pap)
        .filter(key => key.startsWith(startWith))
        .forEach(key => {
            if(pap[key] instanceof Array)
                pap[key]     = []
            else
                delete pap[key]
        })            
}

export default {
    props: ['papsmear','print','disabled'],
    watch : {
        'papsmear' : function(){
            let thisView    = this
            this.needClear  = false
            setTimeout(function(){
                thisView.needClear = true
            },1000)
        },
        'papsmear.adequacy' : function(){
            resetKey(this,'satisfactory')
            resetKey(this,'unsatisfactory')
        },
        'papsmear.categorization' : function(){
            resetKey(this,'negative')
            resetKey(this,'epithelial')
        },
        'papsmear.negativeType' : function(){
            resetKey(this,'negative_')
        },      
        'papsmear.negative_non_neotype' : function(){
            resetKey(this,'negative_non_neo_')
        },
        'papsmear.epithelialType' : function(){
            resetKey(this,'epithelial_')
        },
        'papsmear.epithelial_squamous' : function(){
            resetKey(this,'epithelial_squamous_')
        },
        'papsmear.epithelial_glandular' : function(){
            resetKey(this,'epithelial_glandular_')
        },
    },

    data(){
        return {
            needClear: true
        }
    },
}
</script>
<style scoped>

.doc-form input[type="text"]{
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
} 

.row{
    margin-bottom: 0.5rem;
}


@media print{
    pre{
        border: none;
    }
}

.custom-control{
    margin-bottom: 0.5rem;
}

.doc-form > div > div[role='group']{
    margin-left: 1.5rem;
} 

.custom-control-input:disabled{
    color:black !important;
}

.custom-control-label{
    color:black !important;
}
</style>