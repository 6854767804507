<template>
    <b-container>
        <b-row class="px-3">
            <h2 class="my-3">Reports</h2>
            <div class="text-right ml-auto my-auto">
                <b-form-group class="mb-0">
                    <b-input-group>
                        <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                            <b-dropdown-item
                                @click="search.by=$event.srcElement.text.trim()"
                                v-for="option in search.options"
                                :key="option"
                            >{{option}}
                            </b-dropdown-item>
                        </b-dropdown>
                        <template v-if="search.by == 'name' || search.by == 'surname' || search.by == 'hn'">
                            <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
                        </template>
                        <template v-if="search.by == 'doctor'">
                            <b-form-input list="doctor-name-list"                             
                                @change="selectDoctor" :value="doctor.name"  
                                :disabled="!this.doctors.length" 
                                :state="Boolean(search.doctor)"
                            />
                            <datalist id="doctor-name-list">
                                <option v-for="doctor in doctors" :key="doctor._id">{{doctor.name}}</option>
                            </datalist>
                        </template>

                        <template v-if="search.by == 'text'" >
                            <b-dropdown slot="prepend" :text="search.field" variant="outline-info">
                                <b-dropdown-item
                                    @click="search.field=$event.srcElement.text.trim()"
                                    v-for="option in search.fields"
                                    :key="option"
                                >{{option}}
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-dropdown slot="prepend" :text="search.version" variant="outline-primary">
                                <b-dropdown-item
                                    @click="search.version=$event.srcElement.text.trim()"
                                    v-for="option in search.versions"
                                    :key="option"
                                >{{option}}
                                </b-dropdown-item>
                            </b-dropdown>
                            <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
                        </template>

                        <b-input-group-append>
                            <b-button size="sm" @click="searchData" :disabled="!canSearch"><i class="fas fa-search"></i>Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </b-row>        
        <b-table striped hover :items="reports" :fields="fields">
            <template v-slot:cell(date)="row">
                {{midToDate(row.item._id).toISOString().substr(0,10)}}
                <i class="fas fa-star-of-life text-danger" v-if="row.item.important"></i>
            </template>
            <template v-slot:cell(specimen.number)="row">
                <router-link :to="{ name: 'ReportInfo', params: {id:row.item._id} }" v-if="row.item.specimen">
                    {{row.item.specimen.number.substr(0,3)}}-{{row.item.specimen.number.substr(3,4)}}
                </router-link>
            </template>
            <template v-slot:cell(name)="row">                
                <span v-if="row.item.patient">{{row.item.patient.title}} {{row.item.patient.name}} {{row.item.patient.surname}}</span>                
            </template>
            <template v-slot:cell(specimen.type)="row" >
                <span v-if="row.item.specimen">{{typeByKey[row.item.specimen.type]}}</span>
            </template>
            <template v-slot:cell(length)="row">
                <span v-if="row.item.specimen">
                {{Math.floor((midToDate(row.item._id) - midToDate(row.item.specimen._id))/86400000)}} days
                </span>
            </template>
            <template v-slot:cell(status)="row" >
                <span>{{row.item.status}} ({{row.item.confirmed}})</span>
            </template>
        </b-table>
        <b-pagination
			v-model="paginate.page"
			:total-rows="paginate.counts"
			:per-page="paginate.pagesize"
			align="fill"
        />
    </b-container>
</template>

<script>

import axios from "axios"
import { mapState } from 'vuex'


export default {
    name : 'Reports',

    data(){
        return {
            reports : [],
            query : {},
            paginate : { counts : 0, page:1,pagesize: 30},
            fields : [
                {key:'date',label:'Date'},
                {key:'specimen.number',label:'Number'},
                {key:'patient.hn',label:'HN'},
                {key:'name',label:'Name'},
                {key:'specimen.type',label:'Type'},
                {key:'status',label:'Status'},                
            ],
            search: {
                options: ['name','surname','hn','doctor','text'],
                keyword: "",
                doctor: null,
                start : '',
                end : '',
                by: "name",
                fields      : ['diagnosis','gross','site','detail','comment','papsmear.comment','outlab'],
                field       : 'diagnosis',
                versions    : ['current','confirmed'],
                version      : 'current',                
            },
            typeByKey : {
                aoutlab: "Out lab",
                cellblock: "Cell-block",
                nongyne: "Non-gyne",
                papsmear: "Pap smear",
                slidereview: "Slide review",
                tissueorgan: "Tissue/organ",
                liquidbase: "Liquid-based",
                frozen: "Frozen Section"
            }
        }
    },

    mounted(){
        axios.get(`/api/reports`).then(res => {
            this.reports	        = res.data
            this.paginate.counts    = res.headers.counts
            this.paginate.pagesize  = res.headers.pagesize
        })
        
    },

    computed: {
        ...mapState('user',{users: state => state.data}),

        canSearch(){
            if(this.search.by != 'doctor')  return true
            return Boolean(this.search.doctor)            
        },

        doctors(){
            return this.users.filter( u => u.role =='doctor' || u.role =='oncologist' || u.role == 'pathologist' )
        },

        doctor(){
            let doctor      = this.doctors.find(d => d._id == this.search.doctor)
            if(!doctor) return {name:'',title:''}

            let part        = doctor.name.split('.')
            return {name:part[1],title:part[0]}
        },
    },

    watch : {
		'paginate.page' : function(page){
            this.query.page     = page
			axios
			.get('/api/reports/?' + this.getQuery(this.query).join('&'))
			.then(r => this.reports = r.data)			
		}		
	},


    methods: {
        midToDate(id){
            return new Date( parseInt( id.substr(0,8), 16 ) * 1000)
        },

        selectDoctor(input){
            this.search.doctor    = this.doctors.find(d => d.name == input)._id || null
        },

        getQuery(query){
            return Object.keys(query).map( q =>  q + '=' + query[q] )
        },

        searchData(){
            this.query       = {}
            
            if(this.search.by == 'doctor') 
                this.query       = {doctor:this.search.doctor}

            if(this.search.by == 'name')
                this.query       = {name:this.search.keyword}
            
            if(this.search.by == 'surname')
                this.query       = {surname:this.search.keyword}
                
            if(this.search.by == 'hn')
                this.query       = {hn:this.search.keyword}    
                    
            if(this.search.by == 'text')
                this.query       = {field: this.search.field, version:this.search.version,  keyword:this.search.keyword}    
            
            if(this.search.by == 'name' && this.search.keyword == '')
                this.query       = {}


            let queries         = Object.keys(this.query).map( q =>  q + '=' + this.query[q] )

            axios
			.get('/api/reports?' + queries.join('&'))
			.then(res => {
                this.reports            = res.data
                this.paginate.counts    = res.headers.counts
                this.paginate.pagesize  = res.headers.pagesize
            })
        },
    }

}
</script>

<style scoped>
td > a{
    font-family: monospace;
    font-size:1.25rem;
}
</style>