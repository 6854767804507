<template>
    <b-container style="position: fixed;left: calc(50% - 370px);top: calc(50% - 290px);">
        <b-form style="width: 720px;" class="bg-light border border-secondary rounded p-4" @submit="submitLogin" >
            <b-row>
                <b-col>
                    <b-row class="mb-3">
                        <b-col cols="4" class="pr-1">
                            <img src="logomini.png" class="w-100" />
                        </b-col>
                        <b-col cols="8" class="pl-1">
                            <div><b>กลุ่มงานพยาธิวิทยากายวิภาค<br/>โรงพยาบาลยะลา</b></div>
                            <small>โทร 073-244717, 244711 ต่อ 2502</small>
                        </b-col>
                    </b-row>
                    <b-form-group label="Username :">
                        <b-form-input name="username" v-model="loginData.username" />
                    </b-form-group>
                    <b-form-group label="Password :">
                        <b-form-input type="password" v-model="loginData.password"/>
                    </b-form-group>
                    <div>
                        <b-alert
                            fade
                            :show="alert.countDown"
                            @dismissed="alert.countDown=0"
                            variant="warning"                     
                            class="w-100" 
                        >
                        {{alert.message}}
                        </b-alert>
                    </div>
                    <b-button type="submit" class="w-100 my-3" :variant="loginState" @click="submitLogin">Log in</b-button>
                    <ul class="text-danger">
                        <li>เพื่อเป็นการรักษาสิทธิ์และความลับของผู้ป่วย ขอความร่วมมือ งดถ่ายรูปหรือ capture หน้าจอผลตรวจ</li>
                        <li>หากต้องการใบรายงานผล เพื่อนำไปรักษาต่อ หรือนำออกนอกรพ. กรุณากรอกข้อมูลการขอใบรายงานผล ในแบบฟอร์มขอสไลด์/พาราฟินบล็อก/ใบรายงานผล นำส่งที่กลุ่มงานพยาธิวิทยากายวิภาค ชั้น 5 อาคาร 7</li>
                    </ul>
                </b-col>
                <b-col>
                    <h5 class="mb-0">เอกสาร</h5>
                    <small class="mb-2">ver : {{VUE_APP_VERSION}}</small>
                    <ol>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/1s6x363-GSnbswElEvh5hStJ2T0oaDfVt?usp=share_link">การกรอกข้อมูลใบส่งตรวจ</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/19cCvN5Kh00mFL-24V3QWe4YYcdLd0Qah?usp=share_link">การเก็บสิ่งส่งตรวจ</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/1D0cC_QfWCubCIEtudmBxJbmc_btzLVnf?usp=share_link">การขอทบทวนสไลด์ สำหรับแพทย์รพ.ยะลา</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/1THhGwSXO4-cIBlCT68ErvOkbbFDwjT3I?usp=share_link">การขอสไลด์/บล็อกพาราฟิน/ใบรายงานผล เพื่อนำออกนอกรพ.</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/17Acg2md5leRE51Ojjbf7tzz6JVUrRYQs?usp=share_link">การเปิดดูรายงานผล สำหรับหน่วยงานภายนอก</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/1Dr40b0_uRsSKMC9A6yj9NWQ_GGyWsD00?usp=share_link">คู่มือการให้บริการ</a></li>
                        <li><a target="_blank" href="https://drive.google.com/drive/folders/1mHFk9F5pxQDlep8FFe47wjNHdtDAhyU-?usp=share_link">ใบส่งตรวจและแบบฟอร์มต่างๆ</a></li>
                    </ol> 
                    <!-- 
                    <ol>
                        <li><a href="/doc/1.pdf">ระยะเวลาการรายงานผลการตรวจทางพยาธิวิทยา</a></li>
                        <li><a href="/doc/2.pdf">การเก็บสิ่งส่งตรวจเซลล์วิทยานรีเวช (Pap smear)</a></li>
                        <li><a href="/doc/3.pdf">การเก็บสิ่งส่งตรวจเซลล์วิทยาระบบอื่นๆ (non-gyne cytology)</a></li>
                        <li><a href="/doc/4.pdf">การเก็บสิ่งส่งตรวจชิ้นเนื้อทางศัลยพยาธิ</a></li>
                        <li><a href="/doc/5.pdf">ชิ้นเนื้อที่ไม่จำเป็นต้องส่งตรวจทางพยาธิวิทยา</a></li>
                        <li><a href="/doc/6.pdf">เกณฑ์การปฏิเสธสิ่งส่งตรวจ</a></li>
                        <li><a href="/doc/7.pdf">การขอสไลด์พาราฟินบล็อกใบรายงานผล</a></li>
                        <li><a href="/doc/8.pdf">ระยะเวลาในการเก็บรักษาสิ่งส่งตรวจ</a></li>
                    </ol> 
                    -->
                </b-col>
            </b-row>        
        </b-form>
    </b-container>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            VUE_APP_VERSION : process.env.VUE_APP_VERSION,
            loginData   : {username : '',password : '',},
            loginState  : 'primary',
            alert       : {message : '',countDown :0},
        }
    },
    computed:{
        session(){            
            return this.$store.state['session']
        }
    },

    async mounted(){
        let data    = (await axios.get(`/api/session`)).data
        if(data && data.userId){            
            this.$store.dispatch('session/login',data)
        }
        else{
            this.$store.dispatch('session/login',false)
        }
    },

    methods:{
        submitLogin(){
            event.preventDefault()            
            this.loginState = 'secondary'

            axios.post(`/api/session`,this.loginData)
            .then( res => {                
                this.loginState = 'success'
                if(res.data.username) location.reload()
            })
            .catch( err => {
                this.alert.message      = err.response && err.response.data.message
                this.alert.countDown    = 5
                this.loginState = 'danger'
            })
        }        
    }
}
</script>
<style scoped>

ul{
    padding-inline-start: 20px;
}

/* form{
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
} */

</style>
