<template>
    <div>
        <b-row>
            <b-col cols="4" class="pr-0 text-letter">
                <b-form-group label="Number :" :disabled="disabled">
                    <b-form-input maxlength="1" @input="numCode(0)" :value="specimen.number.charAt(0)" autocomplete="off" />
                    <b-form-input type="number" @input="numCode(1)" :value="specimen.number.charAt(1)" autocomplete="off" />
                    <b-form-input type="number" @input="numCode(2)" :value="specimen.number.charAt(2)" autocomplete="off" />
                    <label class="text-center" style="min-width:15px;margin-right: 3px;">-</label>
                    <b-form-input type="number" @input="numCode(3)" :value="specimen.number.charAt(3)" autocomplete="off" />
                    <b-form-input type="number" @input="numCode(4)" :value="specimen.number.charAt(4)" autocomplete="off" />
                    <b-form-input type="number" @input="numCode(5)" :value="specimen.number.charAt(5)" autocomplete="off" />
                    <b-form-input maxlength="1" @input="numCode(6)" :value="specimen.number.charAt(6)" autocomplete="off" />
                    <span></span>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Type :">
                    <b-form-select v-model="specimen.type" :options="specimenType" :disabled="disabled" />
                </b-form-group>
            </b-col>
            <b-col cols="2" class="pr-0">
                <b-form-group label="Date collected:">
                    <b-form-input type="date" v-model="specimen.date" class="px-1" :disabled="disabled"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="2">
                <b-form-group label="HN :">
                    <b-form-input :value="patientInfo.hn" disabled></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="1" class="pl-0">
                <b-form-group label="Profile :">
                    <b-button class="w-100" disabled>Show</b-button>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="pr-1">
                <b-form-group label="Doctor :">
                    <b-input-group :prepend="doctor.title || ' ' ">
                        <b-form-input list="doctor-name-list" @change="selectDoctor" :value="doctor.name" :disabled="!this.doctors.length || disabled"></b-form-input>
                        <datalist id="doctor-name-list">
                            <option v-for="doctor in doctors" :key="doctor._id">{{doctor.name}}</option>
                        </datalist>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="3">
                <b-form-group label="Department :">
                    <b-form-input list="dept-list-id" @change="selectDepartment" :value="deptName" :disabled="!this.departments.length || disabled"></b-form-input>
                    <datalist id="dept-list-id">
                        <option v-for="(dept,index) in departments" v-bind:key="index">{{dept.name}}</option>
                    </datalist>
                </b-form-group>
            </b-col>
            <b-col>
                <b-form-group label="Patient :">
                    <b-form-input :value="patientFullName" disabled></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="1" class="px-0">
                <b-form-group label="Sex :">
                    <b-form-input :value="patientInfo.sex" disabled></b-form-input>
                </b-form-group>
            </b-col>
            <b-col cols="1">
                <b-form-group label="Age :">
                    <b-form-input :value="patientAge" disabled></b-form-input>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="4" class="pr-1">
                <b-form-group label="Pathologist :">
                    <b-input-group :prepend="pathologist.title || ' ' ">
                        <b-form-input list="pathologists-name-list" @change="selectPathologist" :value="pathologist.name" :disabled="!this.doctors.length || disabled"></b-form-input>
                        <datalist id="pathologists-name-list">
                            <option v-for="doctor in pathologists" :key="doctor._id">{{doctor.name}}</option>
                        </datalist>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    props: ['specimen', 'patient', 'disabled'],
    data() {
        return {
            patientInfo: { name: '', hn: '' },
            specimenType: [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
                { value: 'aoutlab', text: 'Outlab' },
                { value: 'frozen', text: 'Frozen section' },
                { value: 'liquidbase', text: 'Liquid-based cyto.(Gyne.)' },
            ],
        }
    },
    computed: {
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),

        doctor() {
            let doctor = this.doctors.find(d => d._id == this.specimen.doctor)
            if (!doctor) return { name: '', title: '' }

            let part = doctor.name.split('.')
            return { name: part[1], title: part[0] }
        },

        pathologist() {
            let doctor = this.doctors.find(d => d._id == this.specimen.pathologist)
            if (!doctor) return { name: '', title: '' }

            let part = doctor.name.split('.')
            return { name: part[1], title: part[0] }
        },

        pathologists() {
            return this.users.filter(u => u.role == 'pathologist')
        },

        doctors() {
            return this.users.filter(u => u.isActive != false && (u.role == 'doctor' || u.role == 'oncologist' || u.role == 'pathologist'))
        },

        deptName() {
            return (this.departments.find(d => d._id == this.specimen.department) || { name: '' }).name
        },

        patientFullName() {
            return [this.patientInfo.title, this.patientInfo.name, this.patientInfo.surname].join(' ')
        },

        patientAge() {
            if (!this.patientInfo.birthDate) return 0
            return (new Date().getYear() + 1900) - parseInt(this.patientInfo.birthDate.substr(0, 4))
        }
    },
    watch: {
        patient(pt) {
            this.patientInfo = pt
        },

        'specimen.patient': function (patientId) {
            if (Boolean(this.patient.name) || !patientId) return;

            fetch(`/api/patients/${patientId}`)
                .then(res => res.json())
                .then(json => {
                    if (!json) return
                    this.patientInfo = json
                })
        }
    },
    methods: {
        numCode(index) {
            if (!event.data) return
            event.srcElement.value = event.data.toUpperCase()
            let no = this.specimen.number
            this.specimen.number = no.substr(0, index) + event.srcElement.value + no.substr(index + 1)


            let next = event.srcElement.nextElementSibling
            if (!next.select) {
                next = (next.nextElementSibling) ? next.nextElementSibling : next
            }
            next.select && next.select()
        },

        selectPathologist(input) {
            let doctor = this.doctors.find(d => d.name == input) || {}
            this.specimen.pathologist = doctor._id || null
        },

        selectDoctor(input) {
            let doctor = this.doctors.find(d => d.name == input) || {}
            this.specimen.doctor = doctor._id || null
            this.specimen.department = doctor.department || null
        },

        selectDepartment(input) {
            let dept = this.departments.find(d => input === d.name) || {}
            this.specimen.department = dept._id || null
        },
    }
}
</script>
<style scoped>
.text-letter input {
    width: 36px;
    margin-right: 3px;
    font-size: 1rem;
    text-align: center;
    padding: 0px;
    display: inline-block;
}

.input-group-text {
    font-family: monospace;
    font-weight: bold;
    font-size: 1.1rem !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* 
.custom-control{
    margin-bottom: 1rem;
}
.doc-form > div > div[role='group']{
    margin-left: 1.5rem;
}

.doc-form input[type="text"]{
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
} 
*/
</style>