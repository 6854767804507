<template>
    <div class="container">
        <b-row class="px-3">
            <h2 class="my-3">Reports</h2>            
            <div class="text-right ml-auto my-auto">
                <b-form>
                    <b-form-group class="mb-0">
                        <b-input-group>
                            <b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
                                <b-dropdown-item @click="search.by=$event.srcElement.text.trim()" v-for="option in search.options" :key="option">{{option}}
                                </b-dropdown-item>
                            </b-dropdown>
                            <template v-if=" ['name','hn'].indexOf(search.by) != -1 ">
                                <b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
                            </template>
                            <template v-if="search.by == 'doctor'">
                                <b-form-input list="doctor-name-list" @change="selectDoctor" :value="doctor.name" :disabled="!this.doctors.length" :state="Boolean(search.doctor)" />
                                <datalist id="doctor-name-list">
                                    <option v-for="doctor in doctors" :key="doctor._id">{{doctor.name}}</option>
                                </datalist>
                            </template>
                            <b-input-group-append>
                                <b-button type="submit" size="sm" @click="searchData" :disabled="!canSearch"><i class="fas fa-search"></i>Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </div>
        </b-row>
        <div class="text-info">* Status P = Official report is pending / C = Official report is confirm </div>
        <b-table striped hover :items="reports" :fields="fields">
            <template v-slot:cell(collected)="row">
                <small v-if="row.item.specimen">{{row.item.specimen.date.substr(2,8)}}</small>
                <span class="text-danger" v-if="row.item.important">✱</span>
            </template>
            <template v-slot:cell(received)="row">
                {{midToDate(row.item.specimen._id).toISOString().substr(0,10)}}
                <span class="text-danger" v-if="row.item.important">✱</span>
            </template>
            <template v-slot:cell(patient.hn)="row">
                <small v-if="row.item.patient">{{row.item.patient.hn}}</small>
            </template>
            <template v-slot:cell(patient.name)="row">
                <span v-if="row.item.patient">{{row.item.patient.title}} {{row.item.patient.name}} {{row.item.patient.surname}}</span>
            </template>
            <template v-slot:cell(specimen.doctor)="row">
                <small v-if="row.item.specimen">{{doctorById(row.item.specimen.doctor)}}</small>
            </template>
            <template v-slot:cell(specimen.type)="row">
                <small v-if="row.item.specimen"> {{typeByKey[row.item.specimen.type]}}</small> <br>
                <small v-if="row.item.specimen" class="text-info">{{row.item.specimen.dataForm.outlab.estimateDate && row.item.specimen.dataForm.outlab.estimateDate.substr(2,8)}}</small>
            </template>
            <template v-slot:cell(status)="row">
                <template v-if="row.item.specimen && row.item.specimen.pathologist">
                    <template v-if="row.item.specimen && (row.item.status != 'Pending' || row.item.specimen.type == 'aoutlab') ">
                        <a target="_blank" href="#" @click="openReport(row.index)">
                            <span>{{doctorById(row.item.specimen.pathologist)}} </span>
                            <span>({{ (row.item.status == 'Confirm')? 'C':'P'}})</span>
                        </a>
                    </template>
                    <template v-else>
                        <span>{{doctorById(row.item.specimen.pathologist)}} </span>
                        <span>({{ (row.item.status == 'Confirm')? 'C':'P'}})</span>                        
                    </template>
                </template>

                <template v-else>
                    <template v-if="row.item.specimen && (row.item.status != 'Pending' || row.item.specimen.type == 'aoutlab') ">
                        <a target="_blank" href="#" @click="openReport(row.index)">{{row.item.status}}</a>
                    </template>
                    <template v-else>
                        <span class="text-muted">{{row.item.status}}</span>
                    </template>
                </template>

            </template>
        </b-table>

        <b-pagination class="mb-3" v-model="paginate.page" :total-rows="paginate.counts" :per-page="paginate.pagesize" align="fill" />

        <b-modal ref="modal" title="Report File">
            <b-card-group deck>
                <div v-for="file in modalFiles" :key="file.filename" class="w-100 my-2">
                    <b-card border-variant="success">
                        <b-card-text>{{file.original}}</b-card-text>
                        <a class="btn btn-outline-secondary w-100" target="_blank" :href="'/uploads/' + file.filename">Download</a>
                    </b-card>
                </div>
                <div class="w-100 my-2">
                    <b-card border-variant="success">
                        <a class="btn btn-primary w-100" target="_blank" :href="'/#/viewer-report/' + modalReport._id ">Open Report</a>
                    </b-card>
                </div>
            </b-card-group>
            <b-textarea class="text-danger" :value="modalReport.note" disabled />
        </b-modal>

    </div>
</template>
<script>

import axios from "axios"
import { mapState } from 'vuex'

export default {
    data() {
        return {
            reports: [],
            query: {},
            paginate: { counts: 0, page: 1, pagesize: 30 },
            fields: [
                { key: 'collected', label: 'Collect' },
                { key: 'patient.hn', label: 'HN' },
                { key: 'patient.name', label: 'Name' },
                { key: 'specimen.doctor', label: 'Doctor' },
                { key: 'specimen.type', label: 'Type' },
                'status'
            ],
            search: {
                options: ['hn', 'name', 'doctor'],
                keyword: "",
                doctor: null,
                start: '',
                end: '',
                by: "hn"
            },
            typeByKey: {
                aoutlab: "Out lab",
                cellblock: "Cell-block",
                nongyne: "Non-gyne",
                papsmear: "Pap smear",
                slidereview: "Slide review",
                tissueorgan: "Tissue/organ",
                frozen: "Frozen Section",
            },
            modalFiles: [],
            modalReport: {},
        }
    },

    mounted() {
        let viewportmeta = document.querySelector('meta[name="viewport"]');
        if (viewportmeta === null) {
            viewportmeta = document.createElement("meta");
            viewportmeta.setAttribute("name", "viewport");
            document.head.appendChild(viewportmeta);
            viewportmeta = document.querySelector('meta[name="viewport"]');
        }

        viewportmeta.setAttribute('content', "width=device-width, initial-scale=1, shrink-to-fit=no");
        setTimeout(function () {
            viewportmeta.setAttribute('content', "width=770")
        }, 300)
    },

    watch: {
        'paginate.page': function (page) {
            this.query.page = page
            let queries = Object.keys(this.query).map(q => q + '=' + this.query[q])

            axios
                .get('/api/reports/?' + queries.join('&'))
                .then(r => this.reports = r.data)
        }
    },

    computed: {
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),


        canSearch() {
            if (this.search.by != 'doctor') return true
            return Boolean(this.search.doctor)
        },

        doctors() {
            return this.users.filter(u => u.role == 'doctor' || u.role == 'oncologist' || u.role == 'pathologist')
        },

        doctor() {
            let doctor = this.doctors.find(d => d._id == this.search.doctor)
            if (!doctor) return { name: '', title: '' }

            let part = doctor.name.split('.')
            return { name: part[1], title: part[0] }
        },
    },

    methods: {
        openReport(index) {
            event.preventDefault()

            let report = this.reports[index]


            if (report.note || (report.files && report.files.length)) {
                this.modalFiles = report.files
                this.modalReport = report
                this.$refs['modal'].show()

                return
            }

            window.open('/#/viewer-report/' + this.reports[index]._id, '_blank');
        },

        selectDoctor(input) {
            this.search.doctor = this.doctors.find(d => d.name == input)._id || null
        },

        doctorById(id) {
            let doctor = this.users.find(d => d._id == id) || { name: '' }
            return doctor.name.split(' ')[0]
        },

        midToDate(id) {
            return new Date(parseInt(id.substr(0, 8), 16) * 1000)
        },

        searchData() {
            event.preventDefault()
            let query = {}

            if (this.search.by == 'doctor')
                this.query = { doctor: this.search.doctor }

            if (this.search.by == 'name')
                this.query = { name: this.search.keyword }

            if (this.search.by == 'hn')
                this.query = { hn: this.search.keyword }

            if (this.search.by == 'name' && this.search.keyword == '') return

            let queries = Object.keys(this.query).map(q => q + '=' + this.query[q])

            axios
                .get('/api/reports?' + queries.join('&'))
                .then(res => {
                    this.reports = res.data
                    this.paginate.counts = res.headers.counts
                    this.paginate.pagesize = res.headers.pagesize
                })
        },
    }

}
</script>

<style scoped>
td > a {
    font-family: monospace;
    font-size: 1rem;
}
</style>