<template>
    <b-modal ref="modal"  title="HOSXP" size="lg" ok-only ok-title="Close">
        <b-row class="mb-3">
			<b-col class="d-flex">
				<h3 class="mb-0 mr-3">{{status}}</h3>
				<b-button size="sm" variant="outline-secondary" @click="connect">Check</b-button>
			</b-col>
			<b-col class="text-right">
				<b-form-group label-cols-sm="4" class="mb-0">
				<b-input-group>
					<b-dropdown slot="prepend" :text="search.by" variant="outline-secondary">
					<b-dropdown-item
						@click="search.by=$event.srcElement.text"
						v-for="option in search.options"
						:key="option"
					>{{option}}</b-dropdown-item>
					</b-dropdown>
					<b-form-input placeholder="Type to Search" autocomplete="off" v-model="search.keyword" />
					<b-input-group-append>
					<b-button variant="primary" size="sm" @click="searchPatient">
						<i class="fas fa-search"></i>Search
					</b-button>
					</b-input-group-append>
				</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<b-table striped hover :items="patients" :fields="fields">
			<template v-slot:cell(actions)="row">				
				<b-button size="sm" variant="outline-secondary" @click="createPatient(row.item)">COPY</b-button>				
			</template>
			<template v-slot:cell(birthday)="row">				
				{{row.item.birthday.substr(0,10)}}
			</template>
		</b-table>

    </b-modal>
    
</template>

<script>

import axios from "axios"

export default {
    data(){
        return {
			isOnline : false,
			status : '',            
            search: {
				options: ['cid','hn','fname'],
				keyword: "",
				by: "hn"
			},
			patients : [],			
			fields: [
				{ key: "cid", label: "CID" },
				{ key: "hn", label: "HN" },
				{ key: "pname", label: "Title" },
				{ key: "fname", label: "First" },
				{ key: "lname", label: "Last" },
				{ key: "birthday", label: "Birth" },
				{ key: "pttype", label: "Type" },
				{ key: "actions", label: "Actions" }
			],
        }
	},
	
	mounted(){
		// this.connect()				
	},

    methods: {
		connect(){
			axios.get(`http://localhost:3000/api/status`)
        	.then(r => {
            	this.status		= 'Connected'
        	})
        	.catch( err => {			
				this.status	= (err.response)? err.response.data.message :  err.message
            	console.log('service not found')
        	})
		},

        searchPatient(){            
			axios.get(`http://localhost:3000/api/search?${this.search.by}=${this.search.keyword}`)
			.then(r => this.patients	= r.data)            
		},
		
		createPatient(patient){
			this.$refs.modal.hide()
			this.$parent.patientForm 			= {
				cid : patient.cid,
				hn : patient.hn,
				birthDate: patient.birthday.substr(0,10),
				sex : (patient.sex=='2')?'female':'male',
				title : patient.pname,
				name : patient.fname,
				surname : patient.lname,
				type : patient.pttype,
			}
			this.$parent.patientSpecimens 		= [];
			this.$parent.$refs["patient-modal"].show();
		},

        save(){
            
        }
    }
    
}
</script>

