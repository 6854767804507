<template>
    <b-container>

        <PatientsBrowserModal @select="selectPatient" ref="patientsBrowerModal" />

        <div class="form-inline my-2 d-print-none">
            <div class="d-inline mt-3 mr-3">
                <h4 class="mb-0"> {{(requisition._id)? '' : 'New'}} Requisition <template v-if="requisition.createdAt">({{requisition.createdAt.substr(0,10)}})</template></h4>
                <pre v-if="requisition._id">Ref : {{requisition._id.substr(0,8)}}</pre>
            </div>

            <b-button variant="warning" class="ml-2" @click="sendSpecimen" v-if="requisition._id && !isDisabled && !requisition.dataForm.sendBy">
                Fill form sender
            </b-button>

            <b-button variant="success" class="ml-2" @click="createSpecimen" v-if="requisition._id && !isDisabled && $store.state['session'].user.role == 'pathologist' " :disabled="!requisition.dataForm.sendBy">
                Create Specimen
            </b-button>

            <div class="ml-auto d-flex">
                <b-alert fade :show="alert.countDown" @dismissed="alert.countDown=0" variant="warning" class="d-inline mb-0 py-1 mr-3">
                    {{alert.message}}
                </b-alert>
                <span class="rounded text-warning" v-if="!requisition.patient">! Select patient before save</span>
                <b-button :variant="((needSave)?'':'outline-') + 'success'" class="ml-2" @click="save" v-if="!isDisabled && requisition.patient">
                    Save
                </b-button>
            </div>
        </div>

        <div class="text-center d-print-block d-none mt-4">
            <h4>{{printTitle[requisition.type]}}</h4>
            <h5>Department of Anatomical Pathology, Yala Hospital</h5>
            <h6>152 Siroros road, Sateng, Mueang, Yala 95000 Tel. (+66) 7324 4711-8 ext 2502</h6>
            <p>(โปรดกรอกข้อมูลให้ครบถ้วนและชัดเจน)</p>
        </div>

        <b-form>
            <div>

                <b-row class="d-print-none">
                    <b-col cols="3" class="pr-0 d-print-none" v-if="!requisition.specimenId">
                        <b-form-group label="Patient :" class="d-print-none">
                            <b-button variant="primary" class="mr-2" @click="openPatientsBrower">Select</b-button>
                            <b-button @click="newPatient" class="mr-2">New</b-button>
                            <b-button @click="editPatient" v-show="requisition.patient" variant="warning">Edit</b-button>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group label="Type :">
                            <b-form-select v-model="requisition.type" :options="specimenType" :disabled="isDisabled" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="2" class="pr-0">
                        <b-form-group label="Date collected:">
                            <b-form-input type="date" v-model="requisition.date" class="px-1" :disabled="isDisabled"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <template v-if="requisition.patient">
                        <b-col>
                            <div class="doc-form d-flex" style="min-width:300px;">
                                <b class="mr-2" style="min-width:70px;">Patient : </b>
                                <b-form-input :value="requisition.patient.title + ' ' + requisition.patient.name + ' ' +  requisition.patient.surname" readonly />
                            </div>
                        </b-col>
                        <b-col cols="2">
                            <div class="doc-form d-flex" style="min-width:90px;">
                                <b class="mr-2" style="min-width:20px;">HN</b>
                                <b-form-input :value="requisition.patient.hn" readonly />
                            </div>
                        </b-col>
                        <b-col cols="2">
                            <div class="doc-form d-flex" style="min-width:50px;">
                                <b class="mr-2" style="min-width:30px;">Age </b>
                                <b-form-input class="text-right px-2" :value="patientAge" readonly />
                                <span class="ml-1">years </span>
                            </div>
                        </b-col>
                    </template>
                </b-row>

                <b-row class="mt-3">

                    <b-col cols="4" class="pr-1">
                        <div class="doc-form d-flex">
                            <b class="mr-1" style="min-width:55px;">Staff :</b>
                            <b-form-input list="doctor-name-list" @change="selectDoctor" :value="(doctor.title || ' ') + ' ' + doctor.name" :disabled="!doctors.length || isDisabled"></b-form-input>
                            <datalist id="doctor-name-list">
                                <option v-for="doctor in doctors" :key="doctor._id">{{doctor.name}}</option>
                            </datalist>
                        </div>
                    </b-col>

                    <b-col cols="3">
                        <div class="doc-form d-flex">
                            <b class="mr-2" style="min-width:50px;">Ward :</b>
                            <b-form-input list="dept-list-id" @change="selectDepartment" :value="deptName" :disabled="!departments.length || isDisabled"></b-form-input>
                            <datalist id="dept-list-id">
                                <option v-for="(dept,index) in departments" v-bind:key="index">{{dept.name}}</option>
                            </datalist>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-form>

        <hr />

        <!-- pap smear -->
        <template v-if="requisition.type == 'papsmear' || requisition.type == 'liquidbase' ">
            <b-row class="px-3">
                <p class="my-auto"><b>Type of specimen</b></p>
                <b-form-radio-group class="d-flex" v-model="requisition.dataForm.type" :disabled="isDisabled">
                    <b-form-radio value="Vagina" class="my-auto mx-4">Vagina</b-form-radio>
                    <b-form-radio value="Cervix" class="my-auto">Cervix</b-form-radio>
                    <b-form-radio value="Endocervix" class="my-auto mx-4">Endocervix</b-form-radio>
                    <b-form-radio value="Endometrium" class="my-auto mx-4">Endometrium</b-form-radio>
                </b-form-radio-group>
            </b-row>

            <b-row class="mt-3 px-3">
                <div class="doc-form d-flex" style="min-width:600px;">
                    <b class="mr-2 mt-1" style="min-width:120px;">Clinical history</b>
                    <b-input size="sm" v-model="requisition.dataForm.clinical_history" />
                </div>
            </b-row>

            <b-row class="mt-3 px-3">
                <div class="doc-form d-flex" style="min-width:600px;">
                    <b class="d-inline mr-1 mt-1">Para</b>
                    <b-input size="sm" v-model="requisition.dataForm.para" />

                    <b class="d-inline mx-1 mt-1">Last</b>
                    <b-input size="sm" v-model="requisition.dataForm.para_last" />
                    <label class="d-inline mr-2 mt-1">yrs</label>

                    <b class="d-inline ml-5 mr-2 mt-2">LMP</b>
                    <input type="date" v-model="requisition.dataForm.lmp_date" />
                </div>
            </b-row>

            <b-row class="doc-form mt-3 px-3">
                <p class="my-auto"><b>Previous treatment</b></p>
                <b-form-radio-group class="d-flex" v-model="requisition.dataForm.previous_treatment" :disabled="isDisabled">
                    <b-form-radio value="Surgery" class="my-auto mx-4">Surgery</b-form-radio>
                    <b-form-radio value="Radiation" class="my-auto">Radiation</b-form-radio>
                    <b-form-radio value="Chemotherapy" class="my-auto mx-4">Chemotherapy</b-form-radio>
                    <b-form-radio value="Hormone" class="my-auto mx-4">Hormone</b-form-radio>
                    <b-form-radio value="Other" class="my-auto">Other</b-form-radio>
                    <b-input size="sm" v-model="requisition.dataForm.previous_treatment_other" v-show="requisition.dataForm.previous_treatment == 'Other'" />
                </b-form-radio-group>
            </b-row>

            <b-row class="doc-form mt-4 px-3">
                <p class="my-auto"><b>Contraception</b></p>
                <b-form-radio-group class="d-flex" v-model="requisition.dataForm.contraception" :disabled="isDisabled">
                    <b-form-radio value="OCP" class="my-auto mx-4">OCP</b-form-radio>
                    <b-form-radio value="Injection" class="my-auto">Injection</b-form-radio>
                    <b-form-radio value="IUD" class="my-auto mx-4">TR</b-form-radio>
                    <b-form-radio value="Hormone" class="my-auto mx-4">Hormone</b-form-radio>
                    <b-form-radio value="Other" class="my-auto">Other</b-form-radio>
                    <b-input size="sm" v-model="requisition.dataForm.contraception_other" v-show="requisition.dataForm.contraception == 'Other'" />
                </b-form-radio-group>
            </b-row>

            <b-row class="mt-4 px-3">
                <div class="doc-form d-flex" style="min-width:600px;">
                    <b class="d-inline mr-2 mt-1" style="min-width:150px;">Clinical diagnosis</b>
                    <b-input size="sm" v-model="requisition.dataForm.clinical_diagnosis" />
                </div>
            </b-row>
        </template>

        <!-- Sub Type  nongyne -->
        <template v-if="requisition.type == 'nongyne' ">
            <b-row>
                <b-col>
                    <b-form-group label=" ">
                        <b-form-radio name="subtype-radios" v-for="type in subtypeOther" :key="'opt_'+type" v-model="requisition.dataForm.subType" :value="type" class="mt-1">{{type}}</b-form-radio>
                        <b-form-radio name="subtype-radios" value="Other" @change="requisition.dataForm.subType ='other'" class="mt-1">Other</b-form-radio>
                        <b-input class="mt-2" v-model="requisition.dataForm.subType" v-show="showOtherField"></b-input>
                    </b-form-group>
                </b-col>
                <b-col cols="4">
                    <b-form-group label="Body fluid">
                        <div v-for="type in subtypeBodyFluid" :key="'opt_'+type" class="mt-1">
                            <b-form-radio class="d-inline" name="subtype-radios" v-model="requisition.dataForm.subType" :value="type">
                                {{type}}
                            </b-form-radio>
                            <span v-if="requisition.dataForm.subType=='Pleural fluid' && type == 'Pleural fluid'">
                                (
                                <b-form-checkbox v-model="requisition.dataForm.subType_sides" class="d-inline" name="checkbox-1" value="right">Right</b-form-checkbox> /
                                <b-form-checkbox v-model="requisition.dataForm.subType_sides" class="d-inline" name="checkbox-2" value="left">Left</b-form-checkbox>
                                )
                            </span>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="5">
                    <b-form-group label="FNA" class="doc-form">
                        <div v-for="type in subtypeFNA" :key="'opt_'+type" class="mt-1">
                            <b-form-radio class="d-inline" name="subtype-radios" v-model="requisition.dataForm.subType" :value="type">
                                {{type}}
                            </b-form-radio>
                            <span v-if="requisition.dataForm.subType == type">
                                (
                                <b-form-checkbox v-model="requisition.dataForm.subType_sides" class="d-inline" name="checkbox-1" value="right">Right</b-form-checkbox> /
                                <b-form-checkbox v-model="requisition.dataForm.subType_sides" class="d-inline" name="checkbox-2" value="left">Left</b-form-checkbox>
                                )
                                <b-input class="d-inline w-25" v-model="requisition.dataForm.subType_text"></b-input>
                            </span>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row class="mt-4 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical Diagnosis</b>
                </b-col>
                <b-col>
                    <b-input class="d-inline" size="sm" v-model="requisition.dataForm.clinical_diagnosis" />
                </b-col>
            </b-row>

            <b-row class="mt-2 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical history</b>
                </b-col>
                <b-col>
                    <b-form-textarea rows="3" v-model="requisition.dataForm.clinical_history" />
                </b-col>
            </b-row>

        </template>

        <!-- cellblock -->
        <template v-if="requisition.type == 'cellblock' || requisition.type == 'tissueorgan'">
            <p><b>Details of specimen</b></p>
            <b-table hover :fields="specimenFields" :items="requisition.dataForm.specimens" class="mt-3 doc-form">
                <template v-slot:cell(organ)="row">
                    <b-input v-model="row.item.organ" :disabled="Boolean(requisition.specimenId)" />
                </template>
                <template v-slot:cell(site)="row">
                    <b-form-radio-group :id="'radio-group-' + row.index" v-model="row.item.site" :options="['right','left']" :name="'radio-site-' + row.index" :disabled="isDisabled" />
                </template>
                <template v-slot:cell(operation)="row">
                    <b-input v-model="row.item.operation" :disabled="isDisabled" />
                </template>
                <template v-slot:cell(action)="row">
                    <b-button variant="danger" size="sm" class="float-right" @click="removeSpecimenAt(row.index)" v-if="!isDisabled">－</b-button>
                </template>
            </b-table>
            <a href="#" @click.prevent="addSpecimen" v-if="!isDisabled">Add Specimen</a>

            <hr />

            <b-row class="mt-4 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical Diagnosis</b>
                </b-col>
                <b-col>
                    <b-input class="d-inline" size="sm" v-model="requisition.dataForm.clinical_diagnosis" />
                </b-col>
            </b-row>

            <b-row class="mt-2 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical history</b>
                </b-col>
                <b-col>
                    <b-form-textarea rows="3" v-model="requisition.dataForm.clinical_history" />
                </b-col>
            </b-row>

            <div class="doc-form mt-3 d-flex">
                <p class="my-auto"><b>Previous treatment</b></p>
                <b-form-radio-group class="d-flex" v-model="requisition.dataForm.previous_surgery" :disabled="isDisabled">
                    <b-form-radio value="No" class="my-auto mx-4">No</b-form-radio>
                    <b-form-radio value="Yes" class="my-auto">Yes</b-form-radio>
                    <b v-show="requisition.dataForm.previous_surgery == 'Yes'" class="mr-2">Type</b>
                    <b-input size="sm" v-model="requisition.dataForm.previous_surgery_type" v-show="requisition.dataForm.previous_surgery == 'Yes'" />
                </b-form-radio-group>
            </div>

            <div class="d-flex mt-3 doc-form">
                <b class="d-inline mr-2 mt-1">Immunohistochemistry </b>
                <b-input v-model="requisition.dataForm.immunohistochemistry" class="" size="sm" />
            </div>

            <div class="d-flex mt-3 doc-form">
                <b class="d-inline mr-2 mt-1">Other </b>
                <b-input v-model="requisition.dataForm.other" class="" size="sm" />
            </div>
        </template>

        <!-- slidereview or frozen -->
        <template v-if="requisition.type == 'slidereview' || requisition.type == 'frozen'">
            <b-row class="mt-4 doc-form" v-if="requisition.type == 'slidereview' ">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Specimen from (Hospital name)</b>
                </b-col>
                <b-col>
                    <b-input class="d-inline" size="sm" v-model="requisition.dataForm.hospital_name" />
                </b-col>
            </b-row>

            <b-row class="mt-4 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical Diagnosis</b>
                </b-col>
                <b-col>
                    <b-input class="d-inline" size="sm" v-model="requisition.dataForm.clinical_diagnosis" />
                </b-col>
            </b-row>

            <b-row class="mt-2 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Clinical history</b>
                </b-col>
                <b-col>
                    <b-form-textarea rows="3" v-model="requisition.dataForm.clinical_history" />
                </b-col>
            </b-row>

            <b-row class="mt-2 doc-form">
                <b-col cols="2">
                    <b class="mr-2 mt-1">Note to pathologist</b>
                </b-col>
                <b-col>
                    <b-form-textarea rows="3" v-model="requisition.dataForm.note_pathologist" />
                </b-col>
            </b-row>

        </template>

        <div class="d-flex mt-5 doc-form">
            <b class="d-inline mr-2 mt-1" style="min-width: 110px;">Requested by </b>
            <b-input v-model="requisition.dataForm.requestBy" class="" size="sm" />
            <b class="d-inline mx-2 mt-1">Telephone </b>
            <b-input v-model="requisition.dataForm.requestTel" class="" size="sm" />
        </div>
        <div class="d-flex mt-2 doc-form" v-if="requisition.dataForm.sendBy">
            <span><b>Send by</b> {{requisition.dataForm.sendBy.name}} <b>Tel :</b> {{requisition.dataForm.sendBy.tel}}</span>
        </div>

        <div class="d-print-none">
            <hr />
            <h4>Logs</h4>
            <div class="w-75">
                <div class="row px-3" v-for="(log,index) in requisition.logs" :key="index">
                    <span class="w-25">{{new Date(log.date).toLocaleString('en-GB')}}</span>
                    <span class="w-25"><b>{{log.action}}</b></span>
                    <span class="w-25">{{usernameById(log.user)}}</span>
                </div>
            </div>
        </div>

        <!-- Patient modal -->
        <b-modal ref="patient-modal" title="Patient Info" @ok="savePatient" size="lg">
            <b-form>
                <b-row>
                    <b-col cols="2">
                        <b-form-group label="Sex :">
                            <b-form-select v-model="patientForm.sex">
                                <option value="male">ชาย</option>
                                <option value="female">หญิง</option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label="Title :">
                            <b-form-input list="patient-title-list" v-model="patientForm.title"></b-form-input>
                            <datalist id="patient-title-list">
                                <option v-for="title in titleOption" :key="title">{{title}}</option>
                            </datalist>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4" class="px-0">
                        <b-form-group label="Name :">
                            <b-form-input v-model="patientForm.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="Surname :">
                            <b-form-input v-model="patientForm.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="3">
                        <b-form-group label="Birth date :">
                            <b-form-input type="date" v-model="patientForm.birthDate"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Age :">
                            <b-form-input type="number" v-model.number="patientForm.ages"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="ID Card :">
                            <b-form-input type="text" maxlength="13" v-model.number="patientForm.cid"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2">
                        <b-form-group label="HN :">
                            <b-form-input v-model="patientForm.hn"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-modal>

        <b-modal ref="sendby-form" title="Send By" @ok="saveSendBy" size="md">
            <b-form>
                <b-form-group label="Name :">
                    <b-form-input v-model="sendBy.name" />
                </b-form-group>

                <b-form-group label="Tel :">
                    <b-form-input v-model="sendBy.tel" />
                </b-form-group>
            </b-form>
        </b-modal>

    </b-container>
</template>

<script>

import { mapState } from 'vuex'
import axios from 'axios'

import OutlabForm from '../components/OutlabForm.vue'
import SpecimenInfo from '../components/SpecimenInfo.vue'

import PatientsBrowserModal from '../components/PatientsBrowserModal.vue'

const ONE_YEAR = (365 * 24 * 60 * 60 * 1000)

let outlabDefault = {
    type: null,
    consultation: {},
    specimen: {},
    genetic: [],
    stain: [],
}

export default {
    components: {
        OutlabForm, SpecimenInfo, PatientsBrowserModal
    },

    data() {
        return {
            sendBy: { name: '', tel: '' },
            patientForm: {},
            titleOption: ["นาย", "นาง", "นางสาว", "เด็กชาย", "เด็กหญิง"],

            requisition: {
                type: '',
                patient: null,
                patientId: null,
                doctor: null,
                department: null,
                date: '',
                dataForm: {
                    specimens: [],
                    clinical_diagnosis: '',
                    clinical_history: '',
                    subType_sides: [],
                }
            },

            specimenFields: [
                { key: 'organ', label: 'Organ(s)' },
                { key: 'site', label: 'Site' },
                { key: 'operation', label: 'Operation / Procedure' },
                { key: 'action', label: '' },
            ],

            printTitle: {
                'papsmear': 'ใบส่งตรวจเซลล์วิทยาทางนรีเวช (Pap smear requisition form)',
                'nongyne': 'ใบส่งตรวจเซลล์วิทยาระบบอ่ืนๆ (Cytologic requisition form; non-gynecology specimen)',
                'cellblock': 'ใบส่งตรวจชิ้นเนื้อทางพยาธิวิทยา ( Pathologic Requisition Form)',
                'tissueorgan': 'ใบส่งตรวจชิ้นเนื้อทางพยาธิวิทยา ( Pathologic Requisition Form)',
                'slidereview': 'แบบฟอร์มส่งสไลด์ และ/หรือ พาราฟินบล็อก เพื่อทบทวน',
                'liquidbase': '',
            },

            specimen: {},
            specimenType: [
                { value: 'papsmear', text: 'Pap smear' },
                { value: 'nongyne', text: 'Non-gyne cytology' },
                { value: 'cellblock', text: 'Cell-block cytology' },
                { value: 'tissueorgan', text: 'Tissue/organ specimen' },
                { value: 'slidereview', text: 'Slide review' },
                // { value: 'aoutlab', text: 'Outlab' },
                // { value: 'frozen', text: 'Frozen section' },
                { value: 'liquidbase', text: 'Liquid-based cyto.(Gyne.)' },
            ],

            labForm: { type: '', consultation: {}, specimen: {}, immuhc: '', genetic: [], genetic_other: '', stain: [], stain_other: '', other: '' },
            containerFields: [
                { key: 'date', label: 'Date' },
                { key: 'user', label: 'User' },
                { key: 'slide', label: 'Slide' },
                { key: 'action', label: '' },
            ],

            subtypeBodyFluid: ['Pleural fluid', 'Pericardial fluid', 'Peritoneal fluid/Ascites', 'Peritoneal washing', 'Cerebrospinal fluid'],
            subtypeFNA: ['Lymph node', 'Thyroid gland', 'Salivary gland', 'Neck mass', 'Breast'],
            subtypeOther: ['Bronchial brushing', 'Bronchial washing', 'Urine', 'Sputum'],

            containerChars: 1,
            containerForm: { A: 1 },
            newRecutAmount: 0,
            stainForm: { afb: 0, gms: 0, pas: 0 },
            patient: {},
            reportId: null,
            alert: { message: '', countDown: 0 },
            needSave: false,
        }
    },

    computed: {
        ...mapState('user', { users: state => state.data }),
        ...mapState("department", { departments: state => state.data }),

        isDisabled() {
            return Boolean(this.requisition.specimenId)
        },
        doctors() {
            return this.users.filter(u => u.isActive != false && (u.role == 'doctor' || u.role == 'oncologist' || u.role == 'pathologist'))
        },

        doctor() {
            let doctor = this.doctors.find(d => d._id == this.requisition.doctor)
            if (!doctor) return { name: '', title: '' }

            let part = doctor.name.split('.')
            return { name: part[1], title: part[0] }
        },

        deptName() {
            return (this.departments.find(d => d._id == this.requisition.department) || { name: '' }).name
        },

        canDelete() {
            return this.$store.state['session'].user.role == 'pathologist'
        },

        patientAge() {
            if (!this.requisition || !this.requisition.patient) return 0
            return Math.floor((Date.now() - new Date(this.requisition.patient.birthDate)) / (365 * 24 * 3600 * 1000))
        },

        showOtherField() {
            return [...this.subtypeBodyFluid, ...this.subtypeFNA, ...this.subtypeOther].indexOf(this.requisition.dataForm.subType) == -1
        },
    },

    async mounted() {
        let thisView = this

        const outlab_role    = ['pathologist','cytotechnologist','scientist','officer']
        if(outlab_role.includes(this.$store.state['session'].user.role)){
            this.specimenType.push({ value: 'aoutlab', text: 'Outlab' })
        }
        
        if (this.$route.params.id == 'new') {
            return
        }

        //For SpecimenInfo
        fetch(`/api/requisitions/${this.$route.params.id}`)
            .then(res => res.json())
            .then(json => {
                this.requisition = json
            })
    },

    watch: {
        'patientForm.birthDate': function (value) {
            let date = new Date(value)
            if (!date) return

            // this.patientForm.ages	= (new Date).getYear() - date.getYear()
            this.patientForm.ages = Math.floor((Date.now() - date) / ONE_YEAR)
        },
    },

    methods: {
        newPatient() {
            this.patientForm = {};
            this.patientSpecimens = [];
            this.$refs["patient-modal"].show();
        },

        editPatient() {
            this.patientForm = this.requisition.patient;
            this.patientSpecimens = [];
            this.$refs["patient-modal"].show();
        },

        savePatient() {
            let method = this.patientForm._id ? "PUT" : "POST";
            let thisView = this;
            let path = this.patientForm._id ? this.patientForm._id : "";

            axios({
                method,
                url: `/api/patients/${path}`,
                data: this.patientForm,
                withCredentials: true
            })
                .then(r => r.data)
                .then(data => {
                    this.$refs["patient-modal"].hide();
                    this.$set(this.requisition, 'patientId', data._id)
                    this.$set(this.requisition, 'patient', data)

                    thisView.patientForm = data;
                })
                .catch(err => {
                    console.log('err', err)
                    //thisView.displayMessage(err.response.data.message)
                });
        },

        sendSpecimen() {
            this.$refs['sendby-form'].show()
        },

        saveSendBy() {
            this.requisition.dataForm.sendBy = this.sendBy
        },

        createSpecimen() {
            this.$router.push({
                name: 'NewSpecimen',
                params: {
                    id: this.requisition.patientId,
                    date:this.requisition.date,
                    requisitionId: this.requisition._id,
                    doctor: this.requisition.doctor,
                    department: this.requisition.department,
                    type: this.requisition.type,
                }
            })
        },

        addSpecimen() {
            this.requisition.dataForm.specimens.push({ organ: '', site: '', operation: '' })
        },

        selectDoctor(input) {
            let doctor = this.doctors.find(d => d.name == input) || {}
            this.requisition.doctor = doctor._id || null
            this.$set(this.requisition, 'department', doctor.department || null)
        },

        selectDepartment(input) {
            let dept = this.departments.find(d => input === d.name) || {}
            this.requisition.department = dept._id || null
        },

        selectPatient(patient) {
            this.requisition.patientId = patient._id
            this.requisition.patient = patient
        },

        openPatientsBrower() {
            this.$refs['patientsBrowerModal'].show()
        },

        confirmDelete() {
            // this.$refs['authen-modal'].show().then(res => {
            //     axios.delete(`/api/specimens/${this.requisition._id}`, this.requisition)
            //         .then(data => {
            //             this.$router.replace(`/specimen`)
            //         })
            //         .catch(err => {
            //             this.alert.message = err.response.data.message || err.message
            //         })
            // })
        },

        usernameById(id) {
            const user = this.users.find(u => u._id === id)
            return user && user.username
        },

        removeSpecimenAt(index) {
            this.requisition.dataForm.specimens.splice(index, 1)
        },

        save(event) {
            let method = (this.requisition._id) ? 'PUT' : 'POST'
            let thisView = this
            let self = this

            // let btn = event.srcElement
            event.srcElement.disabled = true

            axios({
                method,
                url: `/api/requisitions/${(this.requisition._id) ? this.requisition._id : ''}`,
                data: this.requisition,
            })
                .then(r => r.data)
                .then(data => {
                    this.requisition._id = data._id
                    this.requisition.dataForm = data.dataForm
                    this.requisition.createdAt = data.createdAt

                    this.needSave = false
                    thisView.alert.message = 'Save done'

                    if (method == 'POST') {
                        this.$router.replace('/requisition/' + data._id)
                    }
                })
                .catch(err => {
                    thisView.alert.message = err.response.data.message || err.message
                })
                .finally(a => {
                    thisView.alert.countDown = 5
                    event.srcElement.disabled = false
                })
        },

    }

}

</script>

<style scoped>
.input-group-text {
    font-family: monospace;
    font-weight: bold;
    font-size: 1rem !important;
    /* padding-left: 6px !important;
    padding-right: 6px !important; */
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.doc-form input[type="text"] {
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
    background-color: white;
}

.doc-form input[type="date"] {
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: unset;
    padding: 0;
    font-size: 1rem;
    height: 1.6rem;
    background-color: white;
}
</style>
