import Vuex from "vuex"
import Vue  from "vue"
import axios from 'axios'

Vue.use(Vuex)

const restAction    = {
    list ({ commit,state }) {
        commit('SET_STATUS', 'LOADING')
        
        axios
        .get('/api/' + state.pathName , {withCredentials: true})
        .then(r => r.data)
        .then(data => {
            commit('SET_ALL_DATA', data)
            commit('SET_STATUS', 'DONE')
        })
    },

    save({ commit, state },payload){
        let row     = state.data.find( u => u._id == payload._id)
        let index   = state.data.indexOf(row)
        let objId   = (row)? row._id : payload._id

        let method  = (index == -1 && !payload._id)? 'POST' : 'PUT'

        return axios({
            method,
            url     : `/api/${state.pathName}/${(method=='PUT')?objId:''}`,
            data    : payload
        })
        .then(res  => {
            if(index == -1 && !payload._id)
                commit('ADD_DATA', res.data)
            else
                commit('UPDATE_DATA', {index,data:res.data})
        })
        .catch( err => {
            console.log('error msg',err.response.data)
            commit('SET_ERROR_STATE',err.response.data)
        })
    },

    remove ({ commit,state }, payload) {            
        let target      = state.data[payload.index]
        axios
        .delete(`/${state.pathName}/${target._id}`,{withCredentials: true})
        .then(_ => {
            commit('REMOVE_DATA', target)
        })
    },
}


const  reuseMutations   = {
    SET_STATUS(state,payload){
        state.status    = payload
    },
    SET_ERROR_STATE(state,payload){
        state.data.errMsg    = payload
    },
    UPDATE_DATA(state,payload){
        state.status    = 'UPDATE DONE'
        state.data[payload.index]  = payload.data
    },
    SET_ALL_DATA (state, data) {
        state.data   = data
    },
    ADD_DATA (state, newData) {
        state.data.push(newData)
    },
    REMOVE_DATA (state, data) {
        let rows                = state.data
        rows.splice(rows.indexOf(data), 1)
    },
}






const DepartmentStore = {
    namespaced: true,
    state: {      
        data    : [],
        status  : '',
        pathName: 'departments'
    },
    getters: {
        departments: state => state.departments,
    }, 
    actions: restAction, 
    mutations : reuseMutations,
}

const UserStore = {
    namespaced: true,
    state: {      
        data        : [],
        status      : '',
        pathName    : 'users'
      },
    getters: {
        users: state => state.users,
    },    
    actions: restAction, 
    mutations : reuseMutations,
}

const SessionStore = {
    namespaced: true,
    state: {
        user: null,
        pathName : 'session'
      },
    getters: {
        user: state => state.user,
    },    
    actions: {
        login ({ commit,state},payload) {
            state.user  = payload
        },
    }, 
    mutations : reuseMutations,
}

export default new Vuex.Store({
    modules: {
        user : UserStore,        
        session : SessionStore,
        department : DepartmentStore,
    }
})